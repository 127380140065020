import { Component, ViewChild, Input } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { fadeInOut } from '../../services/animations';
import { BootstrapTabDirective } from 'src/app/directives/bootstrap-tab.directive';
import { Router, ActivatedRoute } from '@angular/router';
import { AccountService } from 'src/app/services/account.service';
import { Permission } from 'src/app/models/permission.model';
import { Utilities } from 'src/app/services/utilities';
import { SysproService } from 'src/app/services/syspro.service';
import { localStorageActions } from 'src/app/models/enums';
import { MatSidenav } from '@angular/material';
import { ConfigurationService } from '../../services/configuration.service';


@Component({
    selector: 'customers',
    templateUrl: './customers.component.html',
    styleUrls: ['./customers.component.scss'],
    animations: [fadeInOut]
})
export class CustomersComponent {

  isSettingsActivated = false;
  isNotesActivated = false;
  isMovementsActivated = true;
  isInvoicesActivated = false;
  isCollapsed = false;
  isBankImportActivated = false;
  isBankImportReportActivated = false;
  isManagementActivated = false;
  isAdminActivated = false;
  isStatementsActivated = false;
  isBankOverviewReportActivated = false;

  fragmentSubscription: any;

  readonly customerSettingsTab = 'settings';
  readonly customerNotesTab = 'notes';
  readonly customerMovementsTab = 'movements';
  readonly customerPaymentsTab = 'payments';
  readonly debtorsBankImportTab = 'bankimport';
  readonly debtorsBankImportReportTab = 'bankimportreport';
  readonly debtorsBankOverviewReportTab = 'debtorsbankoverview';
  readonly customerManagementTab = 'management';
  readonly customerAdminTab = 'admin';
  readonly customerStatementsTab = 'statements';


  @Input()
  isManagement: boolean;

  @Input()
  isCart: boolean;
  
  @Input()
  isSearch: boolean;

  @ViewChild('tab')
  tab: BootstrapTabDirective;

  @ViewChild('sidenav')
  sidenav: MatSidenav;

  @ViewChild('searchModal')
  searchModal: ModalDirective;

  id: any = "";

  constructor(private router: Router, private route: ActivatedRoute, private accountService: AccountService, private configurations: ConfigurationService
) {
  }


  ngOnInit() {


    this.isCollapsed = !this.menuExpanded;

    let action = sessionStorage.getItem(localStorageActions.Customer);
    if (action) {
      this.id = action;
      this.fragmentSubscription = this.route.fragment.subscribe(anchor => this.showContent(anchor, this.id));
    } else {

      this.fragmentSubscription = this.route.fragment.subscribe(anchor => this.showContent(anchor));
    }


  }
  
  ngOnDestroy() {
    if (this.fragmentSubscription && !this.fragmentSubscription.closed)
      this.fragmentSubscription.unsubscribe();
    //this.fragmentSubscription.unsubscribe();
  }

  showContent(anchor: string, test?: any) {

    let action = sessionStorage.getItem(localStorageActions.Customer);
    if (action) {
      this.id = action;
    } 

    if (anchor) {
      anchor = anchor.toLowerCase();
      this.setNewTab(anchor);
    }

    this.tab.show(`#${anchor || this.customerMovementsTab}Tab`);
 
  }

  get menuExpanded(): boolean {
    return this.configurations.menuExpanded;
  }
  toggle() {
    this.isCollapsed = !this.isCollapsed;
  }

  setNewTab(fragment) {
    const activeTab = fragment;

    this.isNotesActivated = activeTab == this.customerNotesTab;
    this.isMovementsActivated = activeTab == this.customerMovementsTab;
    this.isInvoicesActivated = activeTab == this.customerPaymentsTab;
    this.isBankImportActivated = activeTab == this.debtorsBankImportTab;
    this.isBankImportReportActivated = activeTab == this.debtorsBankImportReportTab;
    this.isBankOverviewReportActivated = activeTab == this.debtorsBankOverviewReportTab;
    this.isSettingsActivated = activeTab == this.customerSettingsTab;
    this.isManagementActivated = activeTab == this.customerManagementTab;
    this.isAdminActivated = activeTab == this.customerAdminTab;
    this.isStatementsActivated = activeTab == this.customerStatementsTab;


    this.router.navigate([], { fragment: activeTab, queryParams: { customer: this.id } });
  }


  isFragmentEquals(fragment1: string, fragment2: string) {

    if (fragment1 == null) {
      fragment1 = '';
    }

    if (fragment2 == null) {
      fragment2 = '';
    }

    return fragment1.toLowerCase() == fragment2.toLowerCase();
  }


  onShowTab(event) {
    const activeTab = event.target.hash.split('#', 2).pop();

    this.isNotesActivated = activeTab == this.customerNotesTab;
    this.isMovementsActivated = activeTab == this.customerMovementsTab;
    this.isInvoicesActivated = activeTab == this.customerPaymentsTab;
    this.isBankImportActivated = activeTab == this.debtorsBankImportTab;
    this.isBankImportReportActivated = activeTab == this.debtorsBankImportReportTab;
    this.isBankOverviewReportActivated = activeTab == this.debtorsBankOverviewReportTab;
    this.isSettingsActivated = activeTab == this.customerSettingsTab;
    this.isManagementActivated = activeTab == this.customerManagementTab;
    this.isAdminActivated = activeTab == this.customerAdminTab;
    this.isStatementsActivated = activeTab == this.customerStatementsTab;


    this.router.navigate([], { fragment: activeTab });
  }
   

  get canViewCustomers() {
    return true;
  }

  get canViewMovements() {
    return true;//TODO: this.accountService.userHasPermission(Permission.viewCustomerMovementsPermission);
  }

  get canViewPayments() {
    return true;//TODO: this.accountService.userHasPermission(Permission.viewCustomerPaymentsPermission);
  }


  get canViewBankImport() {
    return this.accountService.userHasPermission(Permission.bankImportPermission);
  }

  get canMaintainPrices() {
    return this.accountService.userHasPermission(Permission.maintainCustomerPricingPermission);
  }

  get canMaintainPricesAdmin() {
    return this.accountService.userHasPermission(Permission.customerPricingAdminPermission);
  }


  get canCreateStatementsCustomers() {
    return this.accountService.userHasPermission(Permission.customerStatementsPermission);
  }


}
