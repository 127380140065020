import { Injectable } from '@angular/core';
import { Observable, Subject, forkJoin } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';

import { AccountEndpoint } from './account-endpoint.service';
import { SysproEndpoint } from './syspro-endpoint.service';
import { AuthService } from './auth.service';
import { ArCustomer } from '../models/arcustomer.model';
import { ArNarration } from '../models/arnarration.model';
import { SalesPerson } from '../models/salesperson.model';
import { Inventory } from '../models/inventory.model';
import { CartDetail } from '../models/cart-detail.model';
import { Supplier } from '../models/supplier.model';
import { WHAvailQTY } from '../models/inventory-wh.model';
import { CustomerMovements } from '../models/customer-movements.model';
import { CustomerPayments } from '../models/customer-payments.model';
import { QuoteDetail } from '../models/quote-detail.model';
import { QuoteHeader } from '../models/quote-header.model';
import { QuoteList } from '../models/quote-list.model';
import { Email } from '../models/email.model';
import { ShippingInstr } from '../models/shipping-instructions.model';
import { Establishment } from '../models/company-establishment.model';
import { SalesOrderList } from '../models/salesorder-list.model';
import { SalesOrderHeader } from '../models/salesorder-header.model';
import { SalesOrderDetail } from '../models/salesorder-detail.model';
import { SalesOrderStatus } from '../models/salesorder-status.model';
import { SalesOrderCancelReasonCode } from '../models/salesorder-cancelreasons.model';
import { InvoiceHeader } from '../models/invoice-header.model';
import { InvoiceDetail } from '../models/invoice-detail.model';
import { PurchaseOrderList } from '../models/purchaseorder-list.model';
import { PurchaseOrderHeader } from '../models/purchaseorder-header.model';
import { PurchaseOrderDetail } from '../models/purchaseorder-detail.model';
import { InvoiceReprint } from '../models/invoice-reprint.model';
import { SalesReportData } from '../models/sales-report.model';
import { CreditNoteSalesReport } from '../models/creditnote-report.model';
import { InvoiceTrackingStatus } from '../models/invoice-tracking-status.model';

import { User } from '../models/user.model';
import { CartHeader } from '../models/cart-header.model';
import { CoreAudit } from '../models/core-audit.model';
import { start } from 'repl';
import { ConfigurationSettings } from '../models/configuration-settings.model';
import { TaxCodes } from '../models/tax-code.model';
import { Branch } from '../models/branch.model';
import { InvoiceTrackingProductivity } from '../models/invoice-tracking-productivity.model';
import { QuoteSalesReport } from '../models/quote-sales-report.model';
import { SysproUser } from '../models/syspro-user.model';
import { SalesReportSummary } from '../models/sales-report-summary.model';
import { MySQLDocument } from '../models/mysqldocument.model';
import { QuoteSalesReportData } from '../models/quote-sales-report-data.model';
import { SalesRepData } from '../models/sales-rep.model';
import { SalesRepSummary } from '../models/sales-rep-summary.model';
import { CustomerPOList } from '../models/customer-po-list.model';
import { CustomerSalesConversion } from '../models/customer-sales-conversion.model';
import { InvoiceBarcode } from '../models/invoice-barcode.model';
import { BarcodeReference } from '../models/barcode-reference.model';
import { SalesStats } from '../models/sales-stats.model';
import { Video } from '../models/video.model';
import { strictEqual } from 'assert';
import { Brand } from '../models/brand.model';
import { CMStockCode } from '../models/cms-stockcode.model';
import { StockCodeDocuments } from '../models/cms-stockcode.documents.model';
import { CMCategories } from '../models/cms-category.model';
import { SimpleTree } from '../models/simple-tree.model';
import { DocumentCategory } from '../models/document-category.model';
import { DocumentManagmement } from '../models/document-management.model';
import { InvoiceDocuments } from '../models/invoice-documents.model';
import { AltStockCode } from '../models/alt-stockcodes.model';
import { InventoryAdditional } from '../models/inventory-additional.model';
import { InvoiceTrackingLookup } from '../models/invoice-tracking-lookup.model';
import { BankImport, BankImportHeader } from '../models/bank-import.model';
import { SysproBank } from '../models/syspro-bank.model';
import { SysproPaymentHeader } from '../models/syspro-payment-header.model';
import { SysproPaymentDetail } from '../models/syspro-payment-detail.model';
import { CMCategoryList } from '../models/cms-category-list.model';
import { InfoBoardNotes } from '../models/info-board-notes.model';
import { CoreCustomer } from '../models/core-customer.model';
import { CoreCustomerRule } from '../models/core-customer-rule.model';
import { CoreCustomerPrices } from '../models/core-customer-prices.model';
import { WebOrder } from '../models/web-order.model';
import { WebPayment } from '../models/web-payment.model';
import { WebLineItem } from '../models/web-line-item.model';
import { CMCategoryForStockCodeList } from '../models/cms-categoriesforstockcodes-list.model';
import { CMSAdditionalImage } from '../models/cms-additional-images.model';
import { DebtorsReleaseData } from '../models/debtors-release-data.model';
import { DebtorsReleaseSummary } from '../models/debtors-release-summary.model';
import { InventoryMovements } from '../models/inventory-movements.model';
import { Inbox, InboxMessageDetails, Outbox } from '../models/wapp-inbox.model';
import { OptIns } from '../models/wapp-inbox.model';
import { CustomerOTPReference } from '../models/customerOTPReference.model';
import { CustomerOTPHeader } from '../models/customerOTPHeader.model';
import { CreditNoteDataModel } from '../models/credit-note-data.model';
import { CreditNoteSummaryModel } from '../models/credit-note-summary.model';
import { CreditNoteReportDetailData } from '../models/creditnote-report-detail-data.model';
import { CatalogueActionReport } from '../models/catalogue-action-report.model';
//import { DocumentCategory } from '../models/document-category.model';

@Injectable()
export class SysproService {
     
  constructor(
    private authService: AuthService,
    private sysproEndpoint:SysproEndpoint) {

  }

  get currentUser() {
    return this.authService.currentUser;
  }

  getCustomers(branch?: string) {
    return this.sysproEndpoint.getCustomersEndpoint<ArCustomer[]>(branch);
  }
  
  getCustomerDetail(customerId?: string) {
    return this.sysproEndpoint.getCustomerDetailEndpoint<ArCustomer>(customerId);
  }


  getCustomerOrderConversion(customerId?: string) {
    return this.sysproEndpoint.getCustomerOrderConversionEndpoint<CustomerSalesConversion>(customerId);
  }


  getCustomerSearchByCriteria(searchOn?: string, searchText?: string) {
    return this.sysproEndpoint.getCustomerSearchByCriteriaEndpoint<ArCustomer[]>(searchOn, searchText);
  }

  getCustomerMovements(startDate?: string, endDate?: string,customer?: string) {
    return this.sysproEndpoint.getCustomerMovementsEndpoint<CustomerMovements>(startDate, endDate, customer);
  }


  getCustomerPayments(startDate?: string, endDate?: string, customer?: string) {
    return this.sysproEndpoint.getCustomerPaymentsEndpoint<CustomerPayments>(startDate, endDate, customer);
  }


  getCustomersLoadedForStatements(application?: string, startDate?: string, endDate?: string) {
    return this.sysproEndpoint.getCustomersLoadedForStatementsEndpoint<CustomerOTPHeader>(application, startDate, endDate);
  }


  getCustomersStatementsReadyForEmail(application?: string, startDate?: string, endDate?: string) {
    return this.sysproEndpoint.getCustStatementsReadyForEmailEndpoint<CustomerOTPReference>(application, startDate, endDate);
  }


  updateCustomerNotesHeader(customerId?: string) {
    return this.sysproEndpoint.updateCustomerNotesHeaderEndpoint<ArNarration>(customerId);
  }

  insertCustomerNotes(customerId?: string, line?: number, notation?: string) {
    return this.sysproEndpoint.insertCustomerNotesEndpoint<ArNarration>(customerId, line, notation);
  }

  deleteCustomerNotes(customerId?: string, line?: number) {
    return this.sysproEndpoint.deleteCustomerNotesEndpoint<ArNarration>(customerId, line);
  }

  getCustomerNotes(customerId?: string) {
    return this.sysproEndpoint.getCustomerNotesEndpoint<ArNarration>(customerId);
  }

  GetAllSalesPersons(branch?: string) {
    return this.sysproEndpoint.getSalesPersonsEndpoint<SalesPerson>(branch);
  }

  getInventoryForBranch(branch?: string) {
    return this.sysproEndpoint.getInventoryForBranchEndpoint<Inventory>(branch);
  }


  getInventoryForAdverts(branch?: string) {
    return this.sysproEndpoint.getInventoryForAdvertsEndpoint<any>(branch);
  }


  getStockCodeSearchByCriteria(branch?: string, searchOn?: string, searchText?: string) {
    return this.sysproEndpoint.getStockCodeSearchByCriteriaEndpoint<Inventory>(branch, searchOn, searchText);
  }


  getStockCodeDetail(stockCode?: string) {
    return this.sysproEndpoint.getStockCodeDetailEndpoint<Inventory>(stockCode);
  }


  getStockCodeAdditionalDetail(stockCode?: string) {
    return this.sysproEndpoint.getStockCodeAdditionalDetailEndpoint<InventoryAdditional>(stockCode);
  }


  getAltStockCodeDetail(stockCode?: string) {
    return this.sysproEndpoint.getAltStockCodeDetailEndpoint<AltStockCode[]>(stockCode);
  }

  getStockCearanceFlag(id?: number) {
    return this.sysproEndpoint.getStockCearanceFlagEndpoint<string>(id);
  }

  getStockCodeMovementHistory(stockCode?: string) {
    return this.sysproEndpoint.getStockCodeMovementHistoryEndpoint<InventoryMovements>(stockCode);
  }

  getCategoriesForStockCodesList() {
    return this.sysproEndpoint.getCategoriesForStockCodesListEndpoint<CMCategoryForStockCodeList[]>();
  }

  getStockCodeAvailableQuantities(stockCode?: string) {
    return this.sysproEndpoint.getStockCodeAvailableQuantitiesEndpoint<WHAvailQTY>(stockCode);
  }


  getCartDetail(cartNumber?: string, branch?: string) {
    return this.sysproEndpoint.getCartDetailEndpoint<CartDetail[]>(cartNumber, branch);
  }


  updateCartComments(cartNumber?: string, lineNumber?: number, commentFromLine?: number, comment?: string, commentType?: string) {
    return this.sysproEndpoint.updateCartCommentsEndpoint<CartDetail[]>(cartNumber, lineNumber, commentFromLine, comment, commentType);
  }


  updateCartCommentsForPackSize(cartNumber?: string, lineNumber?: number, commentFromLine?: number, comment?: string, commentType?: string, includeLines?: boolean) {
    return this.sysproEndpoint.updateCartCommentsForPackSizeEndpoint<CartDetail[]>(cartNumber, lineNumber, commentFromLine, comment, commentType, includeLines);
  }

  postCartToQuote(cartNumber?: string) {
    return this.sysproEndpoint.postCartToQuoteEndpoint<any>(cartNumber);
  }


  getUpdateQuoteStatus(quoteNumber?: string, quoteStatus?: string) {
    return this.sysproEndpoint.getUpdateQuoteStatusEndpoint<any>(quoteNumber, quoteStatus);
  }


  postQuoteTotals(quoteNumber?: string, totalIncl?: number, totalExcl?: number, totalDiscount?: number) {
    return this.sysproEndpoint.postQuoteTotalsEndpoint<any>(quoteNumber, totalIncl, totalExcl, totalDiscount);
  }


  updateQuoteComments(quoteNumber?: string, lineNumber?: number, commentFromLine?: number, comment?: string, commentType?: string) {
    return this.sysproEndpoint.updateQuoteCommentsEndpoint<QuoteDetail[]>(quoteNumber, lineNumber, commentFromLine, comment, commentType);
  }


  getCartFromQuote(quoteNumber?: string, cartNumber?: string, branch?: string) {
    return this.sysproEndpoint.getCartFromQuoteEndpoint<any>(quoteNumber, cartNumber, branch);
  }


  getAllWebOrders() {

    return this.sysproEndpoint.getAllWebOrdersEndpoint<WebOrder[]>();
  }


  getWebOrderPaymentInfo(orderId?: number) {

    return this.sysproEndpoint.getWebOrderPaymentInfoEndpoint<WebPayment[]>(orderId);
  }


  updateWebOrderStatus(invoice?: string, status?: string) {

    return this.sysproEndpoint.updateWebOrderStatusEndpoint<any>(invoice, status);
  }

  UpdateWebOrderStatusWithComment(Id?: number, orderDetail?: WebOrder) {

    return this.sysproEndpoint.UpdateWebOrderStatusWithCommentEndpoint<WebOrder>(Id, orderDetail);
  }


  getWebOrderStatus(waybill?: string) {

    return this.sysproEndpoint.getWebOrderStatusEndpoint<WebOrder>(waybill);
  }


  getWebOrderLineInfo(orderId?: number) {

    return this.sysproEndpoint.getWebOrderLineInfoEndpoint<WebLineItem[]>(orderId);
  }

  getQuoteSearchAll(applicationId?: string) {

    return this.sysproEndpoint.getQuoteSearchAllEndpoint<QuoteList[]>(applicationId);
  }

  getQuoteNumberSearchByCriteria(searchOn?: string, searchText?: string) {

    return this.sysproEndpoint.getQuoteNumberSearchByCriteriaEndpoint<QuoteList[]>(searchOn, searchText);
  }

  getQuoteHeader(quoteNumber?: string) {
    return this.sysproEndpoint.getQuoteHeaderEndpoint<QuoteHeader>(quoteNumber);
  }

  getQuoteDetail(quoteNumber?: string) {
    return this.sysproEndpoint.getQuoteDetailEndpoint<QuoteDetail[]>(quoteNumber);
  }

  GetSysproSessionId(user?: User, override?: boolean, compOverride?: string) {
    return this.sysproEndpoint.getSysproSessionIdEndpoint<User>(user, override, compOverride);
  }


  postSalesOrderSyspro(postObject?: any) {
    return this.sysproEndpoint.postSalesOrderSysproEndpoint<SalesOrderHeader>(postObject);
  }

  GetSysproComments() {
    return this.sysproEndpoint.getSysproCommentsEndpoint<string[]>();
  }

  InsertSalesOrderComments(SalesOrder?: string, CartLineNumber?: number, Comment?: string,
    NCommentFromLin?: number, NCommentType?: string, includeLines?: boolean) {
    return this.sysproEndpoint.insertSalesOrderCommentsEndpoint<any>(SalesOrder, CartLineNumber, Comment, NCommentFromLin, NCommentType, includeLines);
  }


  InsertSalesOrderPaymentComments(SalesOrder?: string, CartLineNumber?: number, Comment?: string,
    NCommentFromLin?: number, NCommentType?: string, includeLines?: boolean) {
    return this.sysproEndpoint.insertSalesOrderPaymentCommentsEndpoint<any>(SalesOrder, CartLineNumber, Comment, NCommentFromLin, NCommentType, includeLines);
  }

  UpdateSalesOrderStatus(salesOrder?: string, status?: string, cancelReason?: string, sessionId?: string) {
    return this.sysproEndpoint.postUpdateSalesOrderStatusEndpoint<SalesOrderHeader>(salesOrder, status, cancelReason, sessionId);
  }

  PostSalesOrderUpdateToSyspro(postObject?: any) {
    return this.sysproEndpoint.postSalesOrderUpdateToSysproEndpoint<SalesOrderHeader>(postObject);
  }


  UpdateSalesOrderSyspro(postObject?: any) {
    return this.sysproEndpoint.updateSalesOrderSysproEndpoint<SalesOrderDetail[]>(postObject);
  }

  PostInvoiceToSyspro(salesorder?: string, sessionId?: string) {
    return this.sysproEndpoint.postInvoiceToSysproEndpoint<InvoiceHeader>(salesorder, sessionId);
  }


  InsertPurchaseOrderStaging(postObject: any, salesOrder: string, supplier: string) {
    return this.sysproEndpoint.insertPurchaseOrderStagingEndpoint<PurchaseOrderList>(postObject, salesOrder, supplier);
  }

  PostPurchaseOrderToSyspro(postObject?: any) {
    return this.sysproEndpoint.postPurchaseOrderToSysproEndpoint<PurchaseOrderList>(postObject);
  }


  UpdatePurchaseOrderStatus(PONumber: string, Status: string) {
    return this.sysproEndpoint.updatePurchaseOrderStatusEndpoint<any>(PONumber, Status);
  }

  UpdatePurchaseOrderTotals(postObject: any) {
    return this.sysproEndpoint.updatePurchaseOrderTotalsEndpoint<any>(postObject);
  }

  PostGRNToSyspro(postObject?: any) {
    return this.sysproEndpoint.postGRNToSysproEndpoint<PurchaseOrderHeader>(postObject);
  }


  getSalesOrderSearchAll() {

    return this.sysproEndpoint.getSalesOrderSearchAllEndpoint<SalesOrderList[]>();
  }

  getSalesOrderSearchByCriteria(searchOn?: string, searchText?: string) {
    return this.sysproEndpoint.getSalesOrderSearchByCriteriaEndpoint<SalesOrderList[]>(searchOn, searchText);
  }


  getCreditNoteSearchByCriteria(searchOn?: string, searchText?: string) {
    return this.sysproEndpoint.getCreditNoteSearchByCriteriaEndpoint<SalesOrderList[]>(searchOn, searchText);
  }

  getSalesOrderHeader(salesOrderNumber?: string) {
    return this.sysproEndpoint.getSalesOrderHeaderEndpoint<SalesOrderHeader>(salesOrderNumber);
  }

  getSalesOrderDetail(salesOrderNumber?: string) {
    return this.sysproEndpoint.getSalesOrderDetailEndpoint<SalesOrderDetail[]>(salesOrderNumber);
  }


  updateInvoiceTotals(application?: string, salesOrderNumber?: string, invoiceNumber?: string
    , vat?: number, totalIncl?: number, totalExcl?: number, totalDisc?: number  ) {

    return this.sysproEndpoint.updateInvoiceTotalsEndpoint<any>(application, salesOrderNumber, invoiceNumber, vat, totalIncl, totalExcl, totalDisc);
  }

  getAllSuppliers() {
    return this.sysproEndpoint.getAllSuppliersEndpoint<Supplier[]>();
  }

  //sendEmail(senderName?: string, senderEmail?: string, cc?: string, bcc?: string, recepientName?: string,
  //  recepientEmail?: string, subject?: string, body?: string, attachment?: string) {
  //  return this.sysproEndpoint.getEmailEndpoint<Email>(senderName, senderEmail, cc, bcc, recepientName, recepientEmail, subject, body, attachment);
  //}


  sendEmail(emailDetail?: Email) {
    return this.sysproEndpoint.getEmailEndpoint<Email>(emailDetail);
  }


  sendSMS(test?: string) {
    return this.sysproEndpoint.getSMSEndpoint<any>(test);
  }


  getShippingInstructions() {
    return this.sysproEndpoint.getShippingInstructionsEndpoint<ShippingInstr>();
  }

  getSalesOrderCancelReasonCodes() {
    return this.sysproEndpoint.getSalesOrderCancelReasonCodesEndpoint<SalesOrderCancelReasonCode>();
  }

  getSalesOrderStatuses() {
    return this.sysproEndpoint.getSalesOrderStatusesEndpoint<SalesOrderStatus>();
  }

  getEstablishmentDetails(company?: string, branchCode?: string) {
    return this.sysproEndpoint.getEstablishmentDetailsEndpoint<Establishment>(company, branchCode);
  }


  updateCartStatus(cartNumber?: string, hasNettPrices?: boolean) {
    return this.sysproEndpoint.updateCartStatusEndpoint<any>(cartNumber, hasNettPrices);
  }


  getInvoiceHeader(salesOrderNumber?: string, invoiceNumber?: string) {
    return this.sysproEndpoint.getInvoiceHeaderEndpoint<InvoiceHeader>(salesOrderNumber, invoiceNumber);
  }

  getInvoiceDetail(salesOrderNumber?: string, invoiceNumber?: string) {
    return this.sysproEndpoint.getInvoiceDetailEndpoint<InvoiceDetail[]>(salesOrderNumber, invoiceNumber);
  }

  getCustomerPOList(customer?: string) {
    return this.sysproEndpoint.getCustomerPOListEndpoint<CustomerPOList[]>(customer);
  }

  getPurchaseOrderSearchByCriteria(searchCriteria?: string, searchValue?: string) {
    return this.sysproEndpoint.getPurchaseOrderSearchByCriteriaEndpoint<PurchaseOrderList[]>(searchCriteria, searchValue);
  }



  getPurchaseOrderHeader(purchaseOrder?: string) {
    return this.sysproEndpoint.getPurchaseOrderHeaderEndpoint<PurchaseOrderHeader>(purchaseOrder);
  }

  getPurchaseOrderDetail(poNumber?: string) {
    return this.sysproEndpoint.getPurchaseOrderDetailEndpoint<PurchaseOrderDetail[]>(poNumber);
  }


  postCoreAudit(coreAudit: CoreAudit) {
    return this.sysproEndpoint.PostCoreAuditEndpoint<CoreAudit>(coreAudit);
  }

  getInvoicePrintAuditTrail(startDate?: string, endDate?: string) {
    return this.sysproEndpoint.getInvoiceReprintEndpoint<InvoiceReprint>(startDate, endDate);
  }

  getInvoiceReprintDetails(invoiceNumber?: string) {
    return this.sysproEndpoint.getInvoiceReprintDetailsEndpoint<InvoiceReprint[]>(invoiceNumber);
  }

  getQuoteSalesData(startDate?: string, endDate?: string, company?: string, branch?: string, user?: string) {
    return this.sysproEndpoint.getQuoteSalesDataEndpoint<QuoteSalesReportData[]>(startDate, endDate, company, branch, user);
  }

  getQuoteSalesSummary(startDate?: string, endDate?: string, company?: string, branch?: string, user?: string) {
    return this.sysproEndpoint.getQuoteSalesSummaryEndpoint<QuoteSalesReport[]>(startDate, endDate, company, branch, user);
  }

  getCreditNoteSales(startDate?: string, endDate?: string, branch?: string, applicationId?: string) {
    return this.sysproEndpoint.getCreditNoteSalesEndpoint<CreditNoteSalesReport>(startDate, endDate, branch, applicationId);
  }

  getAllInvoiceTrackingStatus() {
    return this.sysproEndpoint.getAllInvoiceTrackingStatusEndpoint<InvoiceTrackingStatus[]>();
  }

  getInvoiceTrackingStatusControl(startDate?: string, endDate?: string, company?: string, branch?: string, user?: string) {
    return this.sysproEndpoint.getInvoiceTrackingStatusControlEndpoint<InvoiceTrackingStatus[]>(startDate, endDate, company, branch, user);
  }


  getInvoiceTrackingStatusDeliveryControl(startDate?: string, endDate?: string, company?: string, branch?: string, user?: string) {
    return this.sysproEndpoint.getInvoiceTrackingStatusDeliveryControlEndpoint<InvoiceTrackingStatus[]>(startDate, endDate, company, branch, user);
  }

  getInvoiceTrackingStatusCollectionsControl(startDate?: string, endDate?: string, company?: string, branch?: string, user?: string) {
    return this.sysproEndpoint.getInvoiceTrackingStatusCollectionsControlEndpoint<InvoiceTrackingStatus[]>(startDate, endDate, company, branch, user);
  }

  getInvoiceTrackingStatus(salesOrder?: string, invoiceNumber?: string) {
    return this.sysproEndpoint.getInvoiceTrackingStatusEndpoint<InvoiceTrackingStatus>(salesOrder, invoiceNumber);
  }

  postInvoiceTrackingPurge(salesOrder?: string, invoiceNumber?: string, PurgeUser?: string, PurgeReason?: string, LastOperator?: string) {
    return this.sysproEndpoint.postInvoiceTrackingPurgeEndpoint<InvoiceTrackingStatus>(salesOrder, invoiceNumber, PurgeUser, PurgeReason, LastOperator);
  }


  postInvoiceTrackingCollectedUpdate(salesOrder?: string, invoiceNumber?: string, user?: string, status?: string) {
    return this.sysproEndpoint.postInvoiceTrackingCollectedUpdateEndpoint<InvoiceTrackingStatus>(salesOrder, invoiceNumber, user, status);
  }

  deleteInvoiceTrackingDuplicate(salesOrder?: string, invoiceNumber?: string) {
    return this.sysproEndpoint.deleteInvoiceTrackingDuplicateEndpoint<InvoiceTrackingStatus>(salesOrder, invoiceNumber);
  }


  getConfigurationSettings() {
    return this.sysproEndpoint.getConfigurationSettingsEndpoint<ConfigurationSettings[]>();
  }


  deleteConfigurationSettings(ID?: number, Configuration?: string) {
    return this.sysproEndpoint.deleteConfigurationSettingsEndpoint<ConfigurationSettings[]>(ID, Configuration);
  }


  updateConfigurationSettings(Configuration?: string, Value?: string, UserName?: string, EffectiveDate?: string) {
    return this.sysproEndpoint.updateConfigurationSettingsEndpoint<ConfigurationSettings[]>(Configuration, Value, UserName, EffectiveDate);
  }


  updateBranchSettings(Branch?: string, PriceOverridePassword?: string, InvoiceReprintPassword?: string,
    SalesOrderPassword?: string, PriceCode?: string, LedgerCode?: string) {
    return this.sysproEndpoint.updateBranchSettingsEndpoint<Branch[]>(Branch, PriceOverridePassword,
      InvoiceReprintPassword, SalesOrderPassword, PriceCode, LedgerCode);
  }


  getSysproTaxCodes() {
    return this.sysproEndpoint.getSysproTaxCodesEndpoint<TaxCodes[]>();
  }


  getInvoiceTrackingProductivity(startDate?: string, endDate?: string) {
    return this.sysproEndpoint.getInvoiceTrackingProductivityEndpoint<InvoiceTrackingProductivity[]>(startDate, endDate);
  }


  getDebtorsReleaseData(startDate?: string, endDate?: string) {
    return this.sysproEndpoint.getDebtorsReleaseDataEndpoint<DebtorsReleaseData[]>(startDate, endDate);
  }


  getDebtorsReleaseSummary(startDate?: string, endDate?: string) {
    return this.sysproEndpoint.getDebtorsReleaseSummaryEndpoint<DebtorsReleaseSummary[]>(startDate, endDate);
  }

  getCreditNoteReportData(startDate?: string, endDate?: string) {
    return this.sysproEndpoint.getCreditNoteReportDataEndpoint<CreditNoteReportDetailData[]>(startDate, endDate);
  }


   getCreditNoteReportSummary(startDate?: string, endDate?: string) {
     return this.sysproEndpoint.getCreditNoteReportSummaryEndpoint<CreditNoteSummaryModel[]>(startDate, endDate);
  }


  getSalesReportData(startDate?: string, endDate?: string, company?: string, branch?: string, user?: string, documentType?: string) {
    return this.sysproEndpoint.getSalesDataEndpoint<SalesReportData[]>(startDate, endDate, company, branch, user, documentType);
  }


  getSalesReportSummary(startDate?: string, endDate?: string, company?: string, branch?: string, user?: string, documentType?: string) {
    return this.sysproEndpoint.getSalesSummaryEndpoint<SalesReportSummary[]>(startDate, endDate, company, branch, user, documentType);
  }


  getSalesRepData(startDate?: string, endDate?: string, company?: string, branch?: string, user?: string, documentType?: string, rep?: string, customer?: string) {
    return this.sysproEndpoint.getSalesRepDataEndpoint<SalesRepData[]>(startDate, endDate, company, branch, user, documentType, rep, customer);
  }


  getSalesRepSummary(startDate?: string, endDate?: string, company?: string, branch?: string, user?: string, documentType?: string, rep?: string, customer?: string) {
    return this.sysproEndpoint.getSalesRepSummaryEndpoint<SalesRepSummary[]>(startDate, endDate, company, branch, user, documentType, rep, customer);
  }

  getInvoiceBarcodeSyncReport(startDate?: string, endDate?: string, company?: string, branch?: string, customer?: string, documentType?: string) {
    return this.sysproEndpoint.getInvoiceBarcodeSyncReportEndpoint<InvoiceBarcode[]>(startDate, endDate, company, branch, customer, documentType);
  }


  getBarcodeForInvoiceSync(invoice?: string, branch?: string) {
    return this.sysproEndpoint.getBarcodeForInvoiceSyncEndpoint<BarcodeReference[]>(invoice, branch);
  }


  getInvoiceDocumentsSearchByCriteria(searchOn?: string, searchText?: string) {
    return this.sysproEndpoint.getInvoiceDocumentsSearchByCriteriaEndpoint<InvoiceDocuments[]>(searchOn, searchText);
  }


  updateBarcodeExcluded(invoice?: string) {
    return this.sysproEndpoint.updateBarcodeExcludedForInvoiceSyncEndpoint<BarcodeReference[]>(invoice);
  }

  syncFileReferencesByDate(month?: string, day?: string) {
    return this.sysproEndpoint.syncFileReferencesByDateEndpoint<any>(month, day);
  }


  getBarcodesAvailableReport(startDate?: string, endDate?: string, company?: string, branch?: string, status?: string) {
    return this.sysproEndpoint.getBarcodesAvailableReportEndpoint<BarcodeReference[]>(startDate, endDate, company, branch, status);
  }

  getSysproUserDetailList() {
    return this.sysproEndpoint.getSysproUserDetailListEndpoint<SysproUser[]>();
  }

  getDocuments() {
    return this.sysproEndpoint.getMySQLDocumentsEndpoint<MySQLDocument[]>();
  }

  getDocumentsPerMonth(year?: string, month?: string) {
    return this.sysproEndpoint.getDocumentsPerMonth<MySQLDocument[]>(year, month);
  }

  getDocumentByPrimaryKey(barcode?: string, prefix?: string) {
    return this.sysproEndpoint.getDocumentByPrimaryKey<MySQLDocument>(barcode, prefix);
  }

  getDocumentsByRange(from: string, to: string) {
    return this.sysproEndpoint.getDocumentsByRange<MySQLDocument[]>(from, to);
  }

  getDocumentsByDateRange(startD: string, endD: string) {
    return this.sysproEndpoint.getDocumentsByDateRange<MySQLDocument[]>(startD, endD);
  }

  getBarcodeReferences(startD: string, endD: string, company: string, branch: string, batchFrom: string, batchTo: string) {
    return this.sysproEndpoint.getBarcodeReferencesEndpoint<BarcodeReference[]>(startD, endD, company, branch, batchFrom, batchTo);
  }

  getBarcodesUnallocated(startD: string, endD: string, company: string, branch: string, batchFrom: string, batchTo: string) {
    return this.sysproEndpoint.getBarcodesUnallocatedEndpoint<BarcodeReference[]>(startD, endD, company, branch, batchFrom, batchTo);
  }

  getFileStream(fileName: string, bnumber: number) {
    return this.sysproEndpoint.getFileStream<Blob>(fileName, bnumber);
  }

  getPODByInvoice(invoice: string) {
    return this.sysproEndpoint.getPODByInvoice<BarcodeReference[]>(invoice);
  }

  deletePOD(invoice: string, fileNumber: number) {
    return this.sysproEndpoint.deletePOD<BarcodeReference>(invoice, fileNumber);
  }

  savePOD(pod: BarcodeReference, invoice: string) {
    return this.sysproEndpoint.savePOD<BarcodeReference>(pod, invoice);
  }


  updateSalesStats(reference?: string, description?: string, value?: number){
    return this.sysproEndpoint.updateSalesStatsEndpoint<SalesStats>(reference, description, value);
  }

  getSalesStats(startDate?: string, endDate?: string, company?: string, user?: string) {
    return this.sysproEndpoint.getSalesStatsEndpoint<SalesStats>(startDate, endDate, company, user);
  }

  getDocumentCatergories(expandIndex: Number) {
    return this.sysproEndpoint.getDocumentCatergories<string>(expandIndex);
  }

  getVideos() {
    return this.sysproEndpoint.getVideos<Video>();
  }

  editVideo(video: Video, id: number) {
    return this.sysproEndpoint.editVideoEndpoint<Video>(video, id);
  }

  newVideo(video: Video) {
    return this.sysproEndpoint.newVideoEndpoint<Video>(video);
  }

  deleteVideo(id: number) {
    return this.sysproEndpoint.deleteVideoEndpoint<Video>(id);
  }


  postFileData(formData: FormData, cmsType?: string, fileName?: string) {
    return this.sysproEndpoint.postFileDataEndPoint<any>(formData, cmsType, fileName);
  }


  postAdditionalFileData(formData: FormData, cmsType?: string, fileName?: string) {
    return this.sysproEndpoint.postAdditionalFileDataEndPoint<any>(formData, cmsType, fileName);
  }

  getBrands() {
    return this.sysproEndpoint.getBrands<Brand>();
  }

  addBrand(brand: Brand) {
    return this.sysproEndpoint.addBrandEndpoint<Brand>(brand);
  }

  editBrand(brand: Brand, id?: number) {
    return this.sysproEndpoint.updateBrandEndpoint<Brand>(brand, id);
  }

  deleteBrand(id?: number) {
    return this.sysproEndpoint.deleteBrandEndpoint<Brand>(id);
  }

  getStockCodesForBrand(id?: number) {
    return this.sysproEndpoint.getStockCodesForBrandEndPoint<CMStockCode[]>(id);
  }


  GetAllCMStockCodes() {
    return this.sysproEndpoint.GetAllCMStockCodesEndpoint<CMStockCode[]>();
  }

  addCMStockCode(stockCode: CMStockCode) {
    return this.sysproEndpoint.addCMStockCodeEndpoint<CMStockCode>(stockCode);
  }

  addNewCMSAdditionalImage(item: CMSAdditionalImage) {
    return this.sysproEndpoint.addNewCMSAdditionalImageEndpoint<CMSAdditionalImage>(item);
  }

  deleteCMSAdditionalImage(id?: number) {
    return this.sysproEndpoint.deleteCMSAdditionalImageEndpoint<CMSAdditionalImage>(id);
  }

  getAdditionalImagesForStockCode(id?: number) {
    return this.sysproEndpoint.getAdditionalImagesForStockCodeEndPoint<CMSAdditionalImage[]>(id);
  }

  updateCMStockCode(stockCode: CMStockCode, id?: number) {
    return this.sysproEndpoint.updateCMStockCodeEndpoint<CMStockCode>(stockCode, id);
  }

  deleteCMStockCode(id?: number) {
    return this.sysproEndpoint.deleteCMStockCodeEndpoint<CMStockCode>(id);
  }

  getDocumentsForStockCode(id?: number) {
    return this.sysproEndpoint.getDocumentsForStockCodeEndPoint<StockCodeDocuments[]>(id);
  }


  getAllCMCategories<T>(name?: string) {

    return this.sysproEndpoint.GetAllCMCategoriesEndpoint<CMCategories[]>(name);
  }

  getStockCodesForCategory(id?: number) {
    return this.sysproEndpoint.getStockCodesForCategoryEndPoint<CMStockCode[]>(id);
  }

  getSimpleDocumentTree(categoryName?: string) {
    return this.sysproEndpoint.getSimpleDocumentTreeEndpoint<SimpleTree[]>(categoryName);
  }

  GetAllDocumentCategoriesList<T>() {

    return this.sysproEndpoint.GetAllDocumentCategoriesListEndpoint<DocumentCategory[]>();
  }


  addDocumentCategory(category: DocumentCategory) {
    return this.sysproEndpoint.addDocumentCategoryEndpoint<DocumentCategory>(category);
  }

  editDocumentCategory(category: DocumentCategory, id?: number) {
    return this.sysproEndpoint.updateDocumentCategoryEndpoint<DocumentCategory>(category, id);
  }

  deleteDocumentCategory(id?: number) {
    return this.sysproEndpoint.deleteDocumentCategoryEndpoint<DocumentCategory>(id);
  }

  getDocumentsForCategory(id?: number) {
    return this.sysproEndpoint.getDocumentsForCategoryEndPoint<DocumentManagmement[]>(id);
  }

  postDocument(formData: FormData, cmsType?: string, fileName?: string) {
    return this.sysproEndpoint.postDocumentEndPoint<any>(formData, cmsType, fileName);
  }

  addNewDoc(doc: DocumentManagmement) {
    return this.sysproEndpoint.addNewDocEndpoint<DocumentManagmement>(doc);
  }


  getAllRepoDocs<T>() {
    return this.sysproEndpoint.getAllRepoDocsEndpoint<DocumentManagmement[]>();
  }

  updateRepoDoc(doc: DocumentManagmement, id?: number) {
    return this.sysproEndpoint.updateRepoDocEndpoint<DocumentManagmement>(doc, id);
  }

  deleteRepoDoc(id?: number) {
    return this.sysproEndpoint.deleteRepoDocEndpoint<DocumentManagmement>(id);
  }


  getAllCMCatergoriesTree(expandIndex: Number) {
    return this.sysproEndpoint.getAllCMCatergoriesTreeEndpoint<string>(expandIndex);
  }


  getAllStockCodesAndCategoriesMissingImages(expandIndex: Number) {
    return this.sysproEndpoint.GetAllStockCodesAndCategoriesMissingImagesEndpoint<CMCategories[]>(expandIndex);
  }


  addCMCategory(category: CMCategories) {
    return this.sysproEndpoint.addCMCategoryEndpoint<CMCategories>(category);
  }


  updateStockCodeImageSyspro(stockCode: string, image?: string) {
    return this.sysproEndpoint.UpdateStockCodeImageSysproEndpoint<Inventory>(stockCode, image);
  }

  updateCMCategory(category: CMCategories, id?: number) {
    return this.sysproEndpoint.updateCMCategoryEndpoint<CMCategories>(category, id);
  }

  deleteCMCategory(id?: number) {
    return this.sysproEndpoint.deleteCMCategoryEndpoint<CMCategories>(id);
  }


  getAllCMSCategoryMissingImages(name?: string) {
    return this.sysproEndpoint.GetAllCMSCategoryMissingImagesEndpoint<CMCategories[]>(name);
  }

  getAllCMStockCodesMissingImages(name?: string) {
    return this.sysproEndpoint.GetAllCMStockCodesMissingImagesEndpoint<CMStockCode[]>(name);
  }


  getAllCMSOrphanFileImages() {
    return this.sysproEndpoint.GetAllCMSOrphanFileImagesEndpoint<any>();
  }


  getAllCMStockCodesMissingDatasheets(name?: string) {
    return this.sysproEndpoint.GetAllCMStockCodesMissingDataSheetsEndpoint<CMStockCode[]>(name);
  }


  getInvoiceTrackingStatusControlDirect(startDate?: string, endDate?: string, company?: string) {
    return this.sysproEndpoint.getInvoiceTrackingStatusControlDirectEndPoint<InvoiceTrackingStatus[]>(startDate, endDate, company);
  }

  addInvoiceTrackingLookup(ITS: InvoiceTrackingLookup) {
    return this.sysproEndpoint.addInvoiceTrackingLookupEndpoint<InvoiceTrackingLookup[]>(ITS);
  }

  getMissingITS() {
    return this.sysproEndpoint.getMissingITSEndpoint<InvoiceTrackingStatus[]>();
  }


  uploadBankFile(formData: FormData, company?: string, fileName?: string, bank?: string, period?: string) {
    return this.sysproEndpoint.uploadBankFilesEndPoint<any>(formData, company, fileName, bank, period);
  }


  postPaymentsToSyspro(postObject?: any) {
    return this.sysproEndpoint.postPaymentsToSysproEndpoint<BankImport>(postObject);
  }

  getBankImportByCompany(company?: string) {
    return this.sysproEndpoint.getBankImportByCompanyEndPoint<BankImport[]>(company);
  }


  getBankImportOverview() {
    return this.sysproEndpoint.getBankImportOverviewEndPoint<BankImportHeader[]>();
  }

  getBankImportByFileName(fileName?: string) {
    return this.sysproEndpoint.getBankImportByFileNameEndPoint<BankImport[]>(fileName);
  }

  updateBankImportLine(postObject?: any, id?: number) {
    return this.sysproEndpoint.updateBankImportLineEndpoint<BankImport>(postObject, id);
  }

  getBankImportLatest(company?: string, bankReference?: string, trnYear?: string, trnMonth?: string, customer?: string, startDate?: string, endDate?: string,
    searchType?: string, fileName?: string, userName?: string) {
    return this.sysproEndpoint.getBankImportLatestEndpoint<BankImport[]>(company, bankReference, trnYear, trnMonth, customer, startDate, endDate, searchType, fileName, userName);
  }

  postSinglePaymentToSyspro(postObject?: any) {
    return this.sysproEndpoint.postSinglePaymentToSysproEndpoint<BankImport>(postObject);
  }



  getSysproBankList(){
    return this.sysproEndpoint.getSysproBankListEndpoint<SysproBank[]>();
  }


  getSysproPaymentRunHeader() {
    return this.sysproEndpoint.getSysproPaymentRunHeaderEndpoint<SysproPaymentHeader[]>();
  }



  getSysproPaymentRunDetails(paymentNumber?: string) {
    return this.sysproEndpoint.getSysproPaymentRunDetailListEndpoint<SysproPaymentDetail[]>(paymentNumber);
  }


  getCategoryCatalogueList(branch?: string, searchOn?: string, searchText?: string, filter?: string) {
    return this.sysproEndpoint.getCategoryCatalogueListEndpoint<CMCategoryList[]>(branch, searchOn, searchText, filter);
  }


  getCatalogueUseReportData(startDate?: string, endDate?: string) {
    return this.sysproEndpoint.getCatalogueUseReportDataEndpoint<CatalogueActionReport[]>(startDate, endDate);
  }


  getAllInfoNotes() {
    return this.sysproEndpoint.getAllInfoNotesEndpoint<InfoBoardNotes[]>();
  }

  addInfoNote(info?: InfoBoardNotes) {
    return this.sysproEndpoint.addInfoNoteEndpoint<InfoBoardNotes[]>(info);
  }

  updateInfoNote(info?: InfoBoardNotes, id?: number) {
    return this.sysproEndpoint.updateInfoNoteEndpoint<InfoBoardNotes[]>(info, id);
  }

  deleteInfoNote(id?: number) {
    return this.sysproEndpoint.deleteInfoNoteEndpoint<InfoBoardNotes[]>(id);
  }

  postInfoFile(formData: FormData, cmsType?: string, fileName?: string) {
    return this.sysproEndpoint.postInfoFileEndPoint<any>(formData, cmsType, fileName);
  }

  getInfoFile(fileName: string, bnumber: number) {
    return this.sysproEndpoint.getInfoFileEndpoint<Blob>(fileName);
  }


    //Core Customers
  getAllPricingCustomers() {
    return this.sysproEndpoint.getAllPricingCustomersEndpoint<CoreCustomer[]>();
  }

  getPricingCustomer(customer: string, application: string) {
    return this.sysproEndpoint.getPricingCustomerEndpoint<CoreCustomer[]>(customer, application);
  }

  addNewPricingCustomer(customer?: CoreCustomer) {
    return this.sysproEndpoint.addNewPricingCustomerEndpoint<CoreCustomer>(customer);
  }

  updatePricingCustomer(customer?: CoreCustomer, id?: number) {
    return this.sysproEndpoint.updatePricingCustomerEndpoint<CoreCustomer>(customer, id);
  }

  //Core Customer Pricing Rules
  getAllPricingCustomerRules(CustomerId?: number) {
    return this.sysproEndpoint.getAllPricingCustomerRulesEndpoint<CoreCustomerRule[]>(CustomerId);
  }

  addNewPricingCustomerRule(rule?: CoreCustomerRule, company?: string,  branch?: string) {
    return this.sysproEndpoint.addNewPricingCustomerRuleEndpoint<CoreCustomerRule>(rule, company, branch);
  }

  updatePricingCustomerRule(rule?: CoreCustomerRule, id?: number, company?: string, branch?: string) {
    return this.sysproEndpoint.updatePricingCustomerRuleEndpoint<CoreCustomerRule>(rule, id, company, branch);
  }

  deletePricingCustomerRule(id?: number) {
    return this.sysproEndpoint.deletePricingCustomerRuleEndpoint<CoreCustomerRule[]>(id);
  }


  //Core Customer Prices
  getAllPricingCustomerPrices(CustomerId?: number) {
    return this.sysproEndpoint.getAllPricingCustomerPricesEndpoint<CoreCustomerPrices[]>(CustomerId);
  }

  getAllPricingCustomerPricesForCustomerCode(CustomerCode?: string, Application?: string) {
    return this.sysproEndpoint.getAllPricingCustomerPricesForCustomerCodeEndpoint<CoreCustomerPrices[]>(CustomerCode, Application);
  }

  getAllPricingCustomerPricesForCustomerCodeInventorySelect(CustomerCode?: string, Application?: string) {
    return this.sysproEndpoint.getAllPricingCustomerPricesForCustomerCodeInventorySelectEndpoint<CoreCustomerPrices[]>(CustomerCode, Application);
  }

  addNewPricingCustomerPrice(price?: CoreCustomerPrices) {
    return this.sysproEndpoint.addNewPricingCustomerPriceEndpoint<CoreCustomerPrices>(price);
  }

  updatePricingCustomerPrice(price?: CoreCustomerPrices, id?: number) {
    return this.sysproEndpoint.updatePricingCustomerPriceEndpoint<CoreCustomerPrices[]>(price, id);
  }

  deletePricingCustomerPrice(id?: number) {
    return this.sysproEndpoint.deletePricingCustomerPriceEndpoint<CoreCustomerPrices[]>(id);
  }


  deleteAllPricingCustomerPricesForRule(ruleId?: number) {
    return this.sysproEndpoint.deleteAllPricingCustomerPricesForRuleEndpoint<CoreCustomerPrices[]>(ruleId);
  }

  getStockCodePriceDetail(stockCode?: string) {
    return this.sysproEndpoint.getStockCodePriceDetailEndpoint<Inventory>(stockCode);
  }


  getSuppliersForCustomerPricing() {
    return this.sysproEndpoint.getSuppliersForCustomerPricingEndpoint<Supplier[]>();
  }

  getStockCodePriceDetailForSupplier(supplier?: string) {
    return this.sysproEndpoint.getStockCodePriceDetailForSupplierEndpoint<Inventory[]>(supplier);
  }


  getStockCodePriceDetailForCategory(categoryId?: number) {
    return this.sysproEndpoint.getStockCodePriceDetailForCategoryEndpoint<Inventory[]>(categoryId);
  }


  applyDefaultPricesToCustomerList(postObject?: any, application?: string, user?: string, enabled?: boolean ) {
    return this.sysproEndpoint.applyDefaultPricesToCustomerListEndpoint<CoreCustomer>(postObject, application, user, enabled);
  }

  resetCustomerPricing(application?: string, customer?: string) {
    return this.sysproEndpoint.resetCustomerPricingEndpoint<CoreCustomer>(application, customer);
  }


  GetAllInboxMessages() {
    return this.sysproEndpoint.getAllInboxMessagesEndpoint<Inbox[]>();
  }


  GetInboxMessagesAsync() {
    return this.sysproEndpoint.getInboxMessagesAsyncEndpoint<InboxMessageDetails[]>();
  }

  GetAllOutboxMessages() {
    return this.sysproEndpoint.getAllOutboxMessagesEndpoint<Outbox[]>();
  }

  SendReplyMessage(sendTo: string, message: string, user: string) {
    return this.sysproEndpoint.sendwappReplyEndpoint<any>(sendTo, message, user);
  }

  sendwappWithQuote(sendTo: string, fileName: string, user: string) {
    return this.sysproEndpoint.sendwappWithQuoteEndpoint<any>(sendTo, fileName, user);
  }

  sendwappWithPDF(sendTo: string, fileName: string, user: string, fileType: string, parameter: string) {
    return this.sysproEndpoint.sendwappWithPDFEndpoint<any>(sendTo, fileName, user, fileType, parameter);
  }

  resendWhatsapp(message?: Outbox, user?: string) {
    return this.sysproEndpoint.resendWhatsappEndpoint<Outbox>(message, user);
  }


  getWappOptInDetails(userName?: string) {
    return this.sysproEndpoint.getWappOptInDetailsEndpoint<OptIns>(userName);
  }

  addWappOptInDetails(userDetails?: OptIns) {
    return this.sysproEndpoint.addWappOptInDetailsEndpoint<OptIns>(userDetails);
  }


  GetUserTel(loggedInUser?: string) {
    return this.sysproEndpoint.getUserTelEndpoint<string>(loggedInUser);
  }


  GetMetaUserDetails(loggedInUser?: string) {
    return this.sysproEndpoint.GetMetaUserDetailsEndpoint<any>(loggedInUser);
  }

  sendTemplateWithFile(filename?: string, fileType?: string, recipient_number?: string, user?: string, parameter?: string) {
    return this.sysproEndpoint.sendTemplateWithFileEndpoint<any>(filename, fileType, recipient_number, user, parameter);
  }

  sendTemplateNoFile(recipient_number?: string, user?: string, parameter?: string) {
    return this.sysproEndpoint.sendTemplateNoFileEndpoint<any>(recipient_number, user, parameter);
  }


  //getDPDtrackAndTrace(waybillNumber?: string) {
  //  return this.sysproEndpoint.getDPDtrackAndTraceEndpoint<Object[]>(waybillNumber);
    
  //}


}
