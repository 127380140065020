
import { Component, OnInit, AfterViewInit, TemplateRef, ViewChild, Input } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { AlertService, DialogType, MessageSeverity } from '../../services/alert.service';
import { AccountService } from '../../services/account.service';
import { FowkesOnlineService } from '../../services/fowkes-online.service';
import { SysproService } from '../../services/syspro.service';
import { ReportService } from '../../services/report.service';
import { Utilities } from '../../services/utilities';
import { Router, ActivatedRoute } from '@angular/router';
import { Permission } from '../../models/permission.model';
import { SysproCustomerViewComponent } from './syspro-customer-view.component';
import { ArCustomerEdit } from 'src/app/models/arcustomer-edit.model';
import { localStorageActions } from 'src/app/models/enums';
import { Inventory } from 'src/app/models/inventory.model';
import { CartHeader } from 'src/app/models/cart-header.model';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { Calculations } from 'src/app/services/calculations.service';
import { QuoteHeader } from 'src/app/models/quote-header.model';
import { Email } from 'src/app/models/email.model';
import { User } from 'src/app/models/user.model';
import { Role } from 'src/app/models/role.model';
import { SalesOrderStatus } from 'src/app/models/salesorder-status.model';
import { SalesOrderCancelReasonCode } from 'src/app/models/salesorder-cancelreasons.model';
import { PurchaseOrderDetail } from 'src/app/models/purchaseorder-detail.model';
import { PurchaseOrderHeader } from 'src/app/models/purchaseorder-header.model';
import { POExtended } from '../../models/purchaseorder-extended.model';
import { SalesOrderList } from '../../models/salesorder-list.model';
import { PurchaseOrderList } from '../../models/purchaseorder-list.model';


@Component({
  selector: 'syspro-purchaseorder-view',
  templateUrl: './syspro-purchaseorder-view.component.html',
  styleUrls: ['./syspro-purchaseorder-view.component.scss']
})
export class SysproPurchaseOrderViewComponent implements OnInit, AfterViewInit {

  private cart: CartHeader = new CartHeader();

  uniqueId: string = Utilities.uniqueId();
  columns: any[] = [];
    rows: PurchaseOrderDetail[] = [];
  rowsCache: PurchaseOrderDetail[] = [];
    //cartRows: CartDetail[] = [];
    editedCustomer: ArCustomerEdit;
    sourceCustomer: ArCustomerEdit;
    editingCustomerName: { customer: string };
    loadingIndicator: boolean;

    emailObject: Email = new Email();

    @ViewChild('indexTemplate')
    indexTemplate: TemplateRef<any>;


  @ViewChild('stockCodeTemplate')
  stockCodeTemplate: TemplateRef<any>;

  @ViewChild('descriptionTemplate')
  descriptionTemplate: TemplateRef<any>;

    @ViewChild('orderQtyTemplate')
    orderQtyTemplate: TemplateRef<any>;

    @ViewChild('discountTemplate')
    discountTemplate: TemplateRef<any>;

    @ViewChild('priceTemplate')
    priceTemplate: TemplateRef<any>;

    @ViewChild('totalTemplate')
    totalTemplate: TemplateRef<any>;
  
    @ViewChild('actionsTemplate')
  actionsTemplate: TemplateRef<any>;

  @ViewChild('cancelTemplate')
  cancelTemplate: TemplateRef<any>;

    @ViewChild('editorModal')
    editorModal: ModalDirective;

    @ViewChild('customerEditor')
    customerEditor: SysproCustomerViewComponent;
  inventoryList: Inventory[];


  @ViewChild('emailModal')
  emailModal: ModalDirective;
  @ViewChild('pdfModal')
  pdfModal: ModalDirective;
  @ViewChild('itemsModal')
  itemsModal: ModalDirective;

  @Input()
  isManagement: boolean;

  @Input()
  isCart: boolean;
    cartId: string;
    id: string;
    gridHeight: number = 0;
  TotalItems: number = 0;
  TotalLines: number = 0;
    TotalExcl: number = 0;
    TotalDiscount: number = 0;
    TotalExclAfterDiscount: number = 0;
    TotalVat: number = 0;
    TotalIncl: number = 0;
    isSaving: boolean;
    selectedItems: any;
    isNavigating: boolean;
    cartSaved: boolean;
    quoteNumber: any;
    quoteId: string;
  quote: QuoteHeader;
  salesOrderNumber: string;
  purchaseOrderNumber: string;
 // salesOrder: SalesOrderHeader;
  purchaseOrder: PurchaseOrderHeader = new PurchaseOrderHeader();
    pdfName: string;
    isUpdating: any;
  quoteHeader: QuoteHeader = new QuoteHeader();
    user: any;
    allRoles: any[];
    src: string;
    OrderStatuses: SalesOrderStatus;
  CancelCodes: SalesOrderCancelReasonCode;
  CancelReason: string;

  @ViewChild('cancelreasons')
  private cancelreasons;

  @ViewChild('cancelreasonsSelector')
  private cancelreasonsSelector;
  PoObject: POExtended = new POExtended();
    body: string;
  showInternalRef: boolean;
  totalsNotUpdated: boolean;


  @ViewChild('searchResultSelector')
  private searchResultSelector;
  private selectUndefinedOptionValue: string = "Please Select";

  @Input()
  stockCode: string;
  searchResults: PurchaseOrderList[];
  searchType: string;
  searchText: string;
  searchResultSelected: PurchaseOrderList;
    auditObj: any;
    updatedCounter: number;

  constructor(private alertService: AlertService, private accountService: AccountService, private configurations: ConfigurationService,
    private fowkesService: FowkesOnlineService, private sysproService: SysproService, private reportService: ReportService, private router: Router, private route: ActivatedRoute) {
    }


  ngOnInit() {

    let action = sessionStorage.getItem(localStorageActions.Customer);
    let salesOrderNumber = sessionStorage.getItem(localStorageActions.SalesOrderNumber);
    let purchaseOrderNumber = sessionStorage.getItem(localStorageActions.PurchaseOrder);

    if (purchaseOrderNumber && purchaseOrderNumber != "") {

      this.id = (action ? action.trim() : "");
      this.salesOrderNumber = salesOrderNumber;
      this.purchaseOrderNumber = purchaseOrderNumber;

      this.loadData();
      this.searchType = "purchaseorder";
    }
  }


    ngAfterViewInit() {

    }
  

  loadData() {

    this.totalsNotUpdated = true;
    this.updatedCounter = 0;
    console.log("NotUPDATED", this.totalsNotUpdated);
    if (this.purchaseOrderNumber) {

      this.alertService.startLoadingMessage();
      this.loadingIndicator = true;
     
      this.sysproService.getPurchaseOrderHeader(this.purchaseOrderNumber).subscribe(x => this.onCartHeaderLoadSuccessful(x), error => this.onDataLoadFailed(error));
    }

  }


  specificSearch() {
    if (this.searchType && this.searchText != "") {

      this.alertService.startLoadingMessage();
      this.loadingIndicator = true;
      this.searchResults = null;
      this.sysproService.getPurchaseOrderSearchByCriteria(this.searchType, this.searchText).subscribe(
        x => this.onSpecificSearchSuccesful(x), error => this.onSearchFailed(error));
    }
    else {
      this.alertService.showStickyMessage('Empty search', 'Please enter a value to search on',
        MessageSeverity.warn);
    }
  }
  onSearchFailed(error: any): void {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.alertService.showStickyMessage('Load Error', `No results found, try adjusting your search criteria`,
      MessageSeverity.error);
  }


  onSpecificSearchSuccesful(x: PurchaseOrderList[]): void {

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.searchResults = x;
    if (x.length === 1) {
      this.searchResultSelected = x[0];
      this.viewPurchaseOrderSelected();
    }

  }

  viewPurchaseOrderSelected() {
    if (this.searchResultSelected) {
      sessionStorage.removeItem(localStorageActions.PurchaseOrder);
      sessionStorage.setItem(localStorageActions.PurchaseOrder, this.searchResultSelected.purchaseOrder);
      sessionStorage.removeItem(localStorageActions.Customer);
      sessionStorage.setItem(localStorageActions.Customer, this.searchResultSelected.customer);
      sessionStorage.removeItem(localStorageActions.SalesOrderNumber);
      sessionStorage.setItem(localStorageActions.SalesOrderNumber, this.searchResultSelected.salesOrder);
      this.id = this.searchResultSelected.customer;
      this.salesOrderNumber = this.searchResultSelected.salesOrder;
      this.purchaseOrderNumber = this.searchResultSelected.purchaseOrder;
      this.loadData();
      this.router.navigate(['../../purchaseorders'], { fragment: 'purchaseorder'});

    }
  }

  private onCurrentUserDataLoadSuccessful(user: User, roles: Role[]) {
    this.alertService.stopLoadingMessage();
    this.user = user;
    this.allRoles = roles;
  }

  private onCurrentUserDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage('Load Error', `Unable to retrieve user data from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
      MessageSeverity.error, error);

    this.user = new User();
  }


  get branchCode(): string {
    return this.configurations.branchCode;
  }
    
  onCartHeaderLoadSuccessful(order:PurchaseOrderHeader) {
    let res = order;
    this.purchaseOrder = res[0];

    this.sysproService.getPurchaseOrderDetail(this.purchaseOrderNumber).subscribe(x => this.onCartDetailLoadSuccessful(x), error => this.onDataLoadFailed(error));  


    //else {
    //  if (!this.purchaseOrder || !this.purchaseOrder.totalIncl || this.purchaseOrder.totalIncl <= 0 || this.purchaseOrder.totalIncl.toFixed(2) != this.TotalIncl.toFixed(2)) {
    //    //Run the update again
    //    this.purchaseOrder.totalIncl = +this.TotalIncl.toFixed(2);
    //    this.purchaseOrder.totalExcl = +this.TotalExclAfterDiscount.toFixed(2);
    //    this.purchaseOrder.totalDiscount = +this.TotalDiscount.toFixed(2);
    //    this.purchaseOrder.totalVat = +this.TotalVat.toFixed(2);

    //    this.sysproService.UpdatePurchaseOrderTotals(this.purchaseOrder).subscribe(x => this.totalsUpdated(), error => this.onDataLoadFailed(error));
    //  }
    //}
   

    //this.alertService.stopLoadingMessage();
    //this.loadingIndicator = false;
  }

  purchaseOrderStaginInserted(x: PurchaseOrderList): void {
    this.sysproService.getPurchaseOrderHeader(this.purchaseOrderNumber).subscribe(x => this.onCartHeaderLoadSuccessful(x), error => this.onDataLoadFailed(error));
  }


  onCartDetailLoadSuccessful(purchaseOrderDetail: PurchaseOrderDetail[]) {

    this.rowsCache = [...purchaseOrderDetail];

    const updateRows = this.rowsCache.filter(x => x.orderQty >= 0.1);
    this.TotalLines = updateRows.filter(x => x.lineType !== "6").length;

    const vat = this.purchaseOrder.vatPercentage;

    this.TotalItems = 0;
    this.TotalExcl = 0;
    this.TotalDiscount = 0;
    this.TotalExclAfterDiscount = 0;
    this.TotalVat = 0;
    this.TotalIncl = 0;

    for (var product of updateRows) {
      var lineTotalExclVat = Calculations.calculateBaseLineTotal(product.orderQty, product.sellingPrice, product.priceConvFac, product.lineTotal);
      var lineDiscount = Calculations.calculateLineDiscountTotal(product.orderQty, product.sellingPrice, product.discountPercentage1, product.priceConvFac, 0);

      this.TotalItems += product.orderQty;
      this.TotalExcl += lineTotalExclVat;
      this.TotalDiscount += lineDiscount;

      var lineVat = Calculations.calculateLineVat(lineTotalExclVat, lineDiscount, 0, vat);

      this.TotalVat += lineVat;

      this.calcTotal(product);
    }

    this.TotalExclAfterDiscount = (this.TotalExcl - this.TotalDiscount);
    this.TotalIncl = (this.TotalExclAfterDiscount + this.TotalVat);   
  

    if (!this.purchaseOrder || !this.purchaseOrder.totalIncl || this.purchaseOrder.totalIncl <= 0 || this.purchaseOrder.totalIncl.toFixed(2) != this.TotalIncl.toFixed(2)) {
      //Run the update again
      this.updatedCounter++;
      this.purchaseOrder.totalIncl = +this.TotalIncl.toFixed(2);
      this.purchaseOrder.totalExcl = +this.TotalExclAfterDiscount.toFixed(2);
      this.purchaseOrder.totalDiscount = +this.TotalDiscount.toFixed(2);
      this.purchaseOrder.totalVat = +this.TotalVat.toFixed(2);

      if (this.updatedCounter < 10) {
        this.sysproService.UpdatePurchaseOrderTotals(this.purchaseOrder).subscribe(x => this.totalsUpdated(), error => this.onDataLoadFailed(error));
      }
      else {
        this.alertService.showStickyMessage('Warning', `Totals could not be updated`,
          MessageSeverity.warn);
      }
    }
    else {
      this.purchaseOrder.totalIncl = +this.TotalIncl.toFixed(2);
      this.purchaseOrder.totalExcl = +this.TotalExclAfterDiscount.toFixed(2);
      this.purchaseOrder.totalDiscount = +this.TotalDiscount.toFixed(2);
      this.purchaseOrder.totalVat = +this.TotalVat.toFixed(2);

      this.totalsNotUpdated = false;
    }

    this.rows = purchaseOrderDetail;

    if (this.purchaseOrder.poNumber == "" && !this.isSaving) {
      this.isSaving = true;
      let supplier = this.purchaseOrder.supplier;
      let user = this.accountService.currentUser;
      user.aspnetApplication = this.application;
      const detail = this.rows;

      const postObject = {
        "user": user, "header": this.purchaseOrder, "detail": purchaseOrderDetail, "poResult": new PurchaseOrderList()
      };
      //this.auditObj.actionInput = "SUPPLIER :" + supplier + "Values: " + JSON.stringify(postObject);
      this.sysproService.InsertPurchaseOrderStaging(postObject, "TRANSFER", supplier).subscribe(x => this.purchaseOrderStaginInserted(x), error => this.onDataLoadFailed(error));
    }

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
  }

  totalsUpdated(): void {
    this.totalsNotUpdated = false;
    console.log("NotUPDATED", this.totalsNotUpdated);  

    //Reload header
      this.sysproService.getPurchaseOrderHeader(this.purchaseOrderNumber).subscribe(x => this.onCartHeaderLoadSuccessful(x), error => this.onDataLoadFailed(error));
      console.log("Header updated", this.purchaseOrder);
    }

    onDataLoadFailed(error: any) {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        this.alertService.showStickyMessage('Load Error', `Unable to retrieve details from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
            MessageSeverity.error, error);
    }
  
  viewCustomer() {
    this.router.navigate(['../../orders'], { fragment: 'view', queryParams: { customer: this.id, cartId: this.cartId } });
  }

  goAddItems() {
    this.router.navigate(['../../quotes'], { fragment: 'inventory', queryParams: { customer: this.id, cartId: this.cartId } });
  }
  
  goAddNSItems() {
    this.router.navigate(['../../quotes'], { fragment: 'nonstock', queryParams: { customer: this.id, cartId: this.cartId } });
  }


  shortDelivery() {
    this.itemsModal.show();

  }

  itemsReceived() {
    this.alertService.startLoadingMessage("Loading Email...");
    this.loadingIndicator = true;
    this.emailObject.RecepientName = this.purchaseOrder.buyerName;
    this.emailObject.RecepientEmail = this.purchaseOrder.buyerEmail;
    this.emailObject.CC = ""; //this.purchaseOrder.email;
    this.emailObject.BCC = "";
    this.emailObject.Subject = "Items received for PO: " + this.purchaseOrderNumber.substr(7, 8);
    this.emailObject.SenderName = this.accountService.currentUser.fullName;
    this.emailObject.SenderEmail = this.accountService.currentUser.email;
    this.emailObject.SenderBranch = this.branchCode;
    this.emailObject.SenderCompany = this.application;
    this.emailObject.Attachment = this.purchaseOrderNumber;
    this.emailObject.EmailType = "PurchaseOrder";


    this.emailObject.Bod = "Please see purchase Order for Customer: " + this.purchaseOrder.customer;

    this.PoObject.PoNumber = this.purchaseOrderNumber;
    this.PoObject.InternalReference = this.purchaseOrder.comment;
    this.PoObject.SalesTotal = this.TotalExclAfterDiscount;
    this.PoObject.VatTotal = this.TotalVat;
    this.PoObject.ReportTotal = this.TotalIncl;

    this.showInternalRef = true;
    this.reportService.getPurchaseOrderPDF(this.purchaseOrderNumber, this.showInternalRef, this.application ).subscribe(x => this.emailPDF(), error => this.onDataLoadFailed(error));

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
   
  }

  get application(): string {
    return this.configurations.applicationName;
  }

  shortDeliveryEmail() {
    this.alertService.startLoadingMessage("Loading Email...");
    this.loadingIndicator = true;
    this.emailObject.RecepientName = this.purchaseOrder.buyerName;
    this.emailObject.RecepientEmail = this.purchaseOrder.buyerEmail;
    this.emailObject.CC = ""; // this.purchaseOrder.email;
    this.emailObject.BCC = "";
    this.emailObject.Subject = "Stock received but short delivered for PO: " + this.purchaseOrderNumber.substr(7, 8);
    this.emailObject.SenderName = this.accountService.currentUser.fullName;
    this.emailObject.SenderEmail = this.accountService.currentUser.email;
    this.emailObject.SenderBranch = this.branchCode;
    this.emailObject.SenderCompany = this.application;
    this.emailObject.Attachment = this.purchaseOrderNumber;
    this.emailObject.EmailType = "PurchaseOrder";

    this.body = "";
    this.body += '<table style="color:#525252; font-family:Arial, Helvetica, sans-serif; padding:10px; font-size:12">' +
          '<thead>' +
          '<tr>' +
          '<th>StockCode</th>' +
          '<th>Description</th>' +
          '<th>Qty Ordered</th>' +
          '<th>Qty Received</th>' +
          '<th>Qty Short</th>' +
          '</tr>' +
          '</thead>' +
          '<tbody>' +
          '<tr>';

    for (var product of this.rows) {
      this.body += '<td>' + product.stockCode + '</td>';
      this.body += '<td>' + product.stockDescription + '</td>';
      this.body += '<td>' + product.orderQty + '</td>';
      this.body += '<td>' + product.qtyReceived + '</td>';
      this.body += '<td>' + (product.orderQty - product.qtyReceived)  + '</td>';
      this.body += '</tr><tr>';
    }
    this.body += '</tr></tbody></table>';

    this.emailObject.Bod = this.body;

    this.PoObject.PoNumber = this.purchaseOrderNumber;
    this.PoObject.InternalReference = this.purchaseOrder.comment;
    this.PoObject.SalesTotal = this.TotalExclAfterDiscount;
    this.PoObject.VatTotal = this.TotalVat;
    this.PoObject.ReportTotal = this.TotalIncl;

    this.showInternalRef = true;
    this.reportService.getPurchaseOrderPDF(this.purchaseOrderNumber, this.showInternalRef, this.application).subscribe(x => this.emailPDF(), error => this.onDataLoadFailed(error));

    //this.itemsModal.close();
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    this.itemsModal.hide();
  }
  

  onEmailLoadSuccessful() {

    let poNumberShort = (this.purchaseOrderNumber && this.purchaseOrderNumber.length >= 15 ? this.purchaseOrderNumber.substr(7, 8) : this.purchaseOrderNumber);

    this.alertService.startLoadingMessage("Loading Email...");
    this.loadingIndicator = true;
    this.emailObject.RecepientName = "";
    this.emailObject.RecepientEmail = this.purchaseOrder.buyerEmail;
    this.emailObject.CC = ""; //this.purchaseOrder.email;
    this.emailObject.BCC = "";
    this.emailObject.Subject = "Emailing of Purchase Order: " + poNumberShort;
    this.emailObject.SenderName = this.accountService.currentUser.fullName;
    this.emailObject.SenderEmail = this.accountService.currentUser.email;
    this.emailObject.SenderBranch = this.branchCode;
    this.emailObject.SenderCompany = this.application;
    this.emailObject.Attachment = this.purchaseOrderNumber;
    this.emailObject.EmailType = "PurchaseOrder";
    this.emailObject.Bod = "Please view the attached Purchase Order document that can be viewed with any standard PDF reader. ";

    this.PoObject.PoNumber = this.purchaseOrderNumber; 
    this.PoObject.InternalReference = this.purchaseOrder.comment;
    this.PoObject.SalesTotal = this.TotalExclAfterDiscount;
    this.PoObject.VatTotal = this.TotalVat;
    this.PoObject.ReportTotal = this.TotalIncl;

    this.showInternalRef = false;
    this.emailModal.show();
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
  }

  titleCase(str) {
    str = str.toLowerCase();
  return str.replace(/\w\S/g, function (t) { return t.toUpperCase() });
}

  viewPDF() {
    this.reportService.getPurchaseOrderPDF(this.purchaseOrderNumber, this.showInternalRef, this.application).subscribe(x => this.generateFileStream(), error => this.onDataLoadFailed(error));

    
  }

  private generateFileStream(preview?: boolean) {
    this.reportService.getFileStreamPurchaseOrder(this.purchaseOrderNumber).subscribe(x => this.onFileSuccessful(x, preview), error => this.onDataLoadFailed(error));
    }

  previewPDF() {
    this.reportService.getPurchaseOrderPDF(this.purchaseOrderNumber, this.showInternalRef, this.application).subscribe(x => this.generateFileStream(true), error => this.onDataLoadFailed(error));

     }

  emailPDF() {

    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;
    this.reportService.getPurchaseOrderPDF(this.purchaseOrderNumber, this.showInternalRef, this.application).subscribe(x => this.emailPurchaseOrder(), error => this.onDataLoadFailed(error));


  }

    private emailPurchaseOrder() {
        this.sysproService.sendEmail(this.emailObject).subscribe(x => this.onEmailSentSuccessful(x), error => this.onDataLoadFailed(error));
    }

  onEmailSentSuccessful(x: Email): void {

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    this.alertService.showMessage('Success', `Email sent successfully`, MessageSeverity.success);

    //Update Printed status
    this.sysproService.UpdatePurchaseOrderStatus(this.purchaseOrderNumber, "4").subscribe(x => this.onUpdateSuccessful(x), error => this.onDataLoadFailed(error));


    this.emailModal.hide();
  }
    onUpdateSuccessful(x: any): void {
      console.log(x);
    }
  
  getPDF(x: Blob, purchaseOrder: string): void {

    this.reportService.getFileStreamPurchaseOrder(purchaseOrder).subscribe(x => this.onFileSuccessful(x, false), error => this.onDataLoadFailed(error));
  }

  onFileSuccessful(x: Blob, preview: boolean): void {
    var test = x;

    const url = window.URL.createObjectURL(test);
    var newBlob = new Blob([x], { type: "application/pdf" });

    this.src = url;
    //const fileUrl = URL.createObjectURL(newBlob);
    //window.location.href = fileUrl;

    if (!preview) {
      console.log(x);

      // IE doesn't allow using a blob object directly as link href
      // instead it is necessary to use msSaveOrOpenBlob
      //if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      //  window.navigator.msSaveOrOpenBlob(newBlob);
      //  return;
      //}

      // For other browsers: 
      // Create a link pointing to the ObjectURL containing the blob.
      const data = window.URL.createObjectURL(newBlob);

      var link = document.createElement('a');
      link.href = data;
      link.download = "PurchaseOrder_" + this.purchaseOrderNumber + ".pdf";
      // this is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    }
    else {
      //this.pdfModal.show();
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = url;
      document.body.appendChild(iframe);

      setTimeout(() => {
        iframe.contentWindow.print();
      }, 1000);
    }

    //Update Printed status
    this.sysproService.UpdatePurchaseOrderStatus(this.purchaseOrderNumber, "4").subscribe(x => this.onUpdateSuccessful(x), error => this.onDataLoadFailed(error));

  }



  private saveFailedHelper(error: any) {
    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage('Save Error', 'The below errors occured whilst saving your changes:', MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);

    //if (this.changesFailedCallback) {
    //  this.changesFailedCallback();
    //}
  }


  viewSalesOrder() {
    let row = this.purchaseOrder;
    this.setLocalCustomer(row.customer, row.salesOrder);
    this.router.navigate(['../../salesorders'], { fragment: 'view', queryParams: { customer: row.customer } });
  }


  setLocalCustomer(customer: string, salesOrderNumber: string) {

    if (sessionStorage.getItem(localStorageActions.SalesOrderNumber) != salesOrderNumber) {
      sessionStorage.setItem(localStorageActions.SalesOrderNumber, salesOrderNumber);
    }
    if (sessionStorage.getItem(localStorageActions.Customer) != customer) {
      sessionStorage.setItem(localStorageActions.Customer, customer);
    }
  }

  viewInventory() {

  }


  calcReceivedQty(row: any, event) {
    var val = event.target.value;
    row.qtyReceived = val;

    let short = row.orderQty - row.qtyReceived;
    row.qtyShort = short;
  }

  calcOrderQty(row: any, event) {
    var val = event.target.value;
    row.orderQty = val;

    let total = Calculations.getGridLineTotal(row.orderQty, row.sellingPrice, row.priceConvFac, row.discountPercentage1, 0);
    row.lineTotal = Calculations.precise_round(total, 2);
  }


  //calcDisc(row: any, event) {
  //  var val = event.target.value;
  //  row.discountPercentage1 = val;

  //  let total = Calculations.getGridLineTotal(row.orderQty, row.sellingPrice, row.priceConvFac, row.discountPercentage1, 0);
  //  row.lineTotal = Calculations.precise_round(total, 2);
  //}


  calcPrice(row: any, event) {
    var val = event.target.value;
    row.sellingPrice = val;

    let total = Calculations.getGridLineTotal(row.orderQty, row.sellingPrice, row.priceConvFac, row.discountPercentage1, 0);
    row.lineTotal = Calculations.precise_round(total, 2);
  }


  calcTotal(row: any) {
    let total = Calculations.getGridLineTotal(row.orderQty, row.sellingPrice, row.priceConvFac, row.discountPercentage1, 0);
    row.lineTotal = Calculations.precise_round(total, 2);
  }

  setDelete(row: any) {
    if (row.selected) {
      this.selectedItems.push(row.lineNumber);
    }
  }

  viewCustomerNotes(row: ArCustomerEdit) {
    sessionStorage.setItem(localStorageActions.Customer, row.customer);
    this.router.navigate(['../../customers'], { fragment: 'notes', queryParams: { customer: row.customer } });
  }

   get canViewCustomers() {
       return this.accountService.userHasPermission(Permission.viewRolesPermission);
    }

  get canManageCustomers() {
    return this.accountService.userHasPermission(Permission.manageCustomersPermission);
    }
}
