import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';

import { AlertService, DialogType, MessageSeverity } from '../../services/alert.service';
import { ConfigurationService } from '../../services/configuration.service';
import { AppTranslationService } from '../../services/app-translation.service';
import { BootstrapSelectDirective } from '../../directives/bootstrap-select.directive';
import { AccountService } from '../../services/account.service';
import { ThemeManager } from '../../services/theme-manager';
import { Utilities } from '../../services/utilities';
import { Permission } from '../../models/permission.model';
import { FowkesOnlineService } from 'src/app/services/fowkes-online.service';
import { SysproService } from 'src/app/services/syspro.service';
import { Branch } from 'src/app/models/branch.model';
import { Printers } from '../../models/printers.model';
import { PrinterUserMappings } from '../../models/printer-user-mappings.model';
import { PrinterService } from '../../services/printer.service';
import { User } from '../../models/user.model';
import { OptIns } from '../../models/wapp-inbox.model';


@Component({
    selector: 'user-preferences',
    templateUrl: './user-preferences.component.html',
    styleUrls: ['./user-preferences.component.scss']
})
export class UserPreferencesComponent implements OnInit, OnDestroy {

    themeSelectorToggle = true;

    languageChangedSubscription: any;

    @ViewChild('languageSelector')
    languageSelector: BootstrapSelectDirective;

    @ViewChild('homePageSelector')
    homePageSelector: BootstrapSelectDirective;
  allBranches: Branch[] = [];


  @ViewChild('branches')
  private branches;

  @ViewChild('branchSelector')
  branchSelector: BootstrapSelectDirective;


  selectedPrinter: string;
  selectedBranchCode: string;


  @ViewChild('printerSelector')
  private printerSelector;
  Printers: Printers[] = [];
  printerMappings: PrinterUserMappings[] = [];
  WhatsappNumber: string;
  userOTP: OptIns = new OptIns();

    constructor(
        private alertService: AlertService,
        private translationService: AppTranslationService,
        private accountService: AccountService,
        public themeManager: ThemeManager,
      public configurations: ConfigurationService, private fowkesOnline: FowkesOnlineService, private sysproService: SysproService, private printerService: PrinterService) {
    }

    ngOnInit() {
      
      this.fowkesOnline.getBranches().subscribe(x => this.onBranchLoadSuccessful(x), error => this.onCurrentUserDataLoadFailed(error));
      this.printerService.GetPrinters().subscribe(x => this.onPrintersLoadSuccesful(x), error => this.onDataLoadFailed(error));
      this.reloadFromServer();
      
    }

    ngOnDestroy() {
        //this.languageChangedSubscription.unsubscribe();
    }


  private onBranchLoadSuccessful(branches: Branch[]) {
    this.alertService.stopLoadingMessage();
    this.allBranches = branches;
    setTimeout(() => {
      if (this.branchSelector) {
        this.branchSelector.refresh();
      }
    });


    if (this.configurations.branchCode.trim()) {

      this.selectedBranchCode = this.configurations.branchCode.trim();
    }
    else {
      this.selectedBranchCode = this.accountService.currentUser.branch;
    }
  }

  private onDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage('Load Error', `Unable to retrieve printer data from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
      MessageSeverity.error, error);

  }

  private onCurrentUserDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage('Load Error', `Unable to retrieve user data from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
      MessageSeverity.error, error);

  }


  onPrintersLoadSuccesful(x: Printers[]): void {
    this.Printers = x;

    setTimeout(() => {
      if (this.printerSelector) {
        this.printerSelector.refresh();
      }
      this.homePageSelector.refresh();
    });

    this.selectedPrinter = this.configurations.defaultPrinter.trim();
    if (this.configurations) {
      if (!this.selectedPrinter || this.selectedPrinter.length == 0) {
        this.printerService.GetUserPrinterMappings(this.accountService.currentUser.id).subscribe(x => this.onUserPrintersLoadSuccesful(x), error => this.onDataLoadFailed(error));

      }
      console.log("Printers", this.configurations.defaultPrinter);
    }
  }
  onUserPrintersLoadSuccesful(x: PrinterUserMappings[]): void {

    this.printerMappings = x;   

    if (this.printerMappings.length > 0) {
      const printerForInvoice = this.printerMappings.filter(p => p.reportName === "Invoice");
      this.selectedPrinter = (printerForInvoice.length ? printerForInvoice[0].printerName : this.selectedPrinter);
    }

    console.log("printerMappings", this.configurations.defaultPrinter);
  }

  reloadFromServer() {
    this.alertService.startLoadingMessage();
    this.accountService.getUserDetails(this.accountService.currentUser.id).subscribe(x => this.onUserLoadSuccesful(x), error => this.onDataLoadFailed(error));

    this.accountService.getUserPreferences()
      .subscribe(results => {
        this.alertService.stopLoadingMessage();

        this.configurations.import(results);
        if (!this.selectedPrinter || this.selectedPrinter.length == 0) {
          this.printerService.GetUserPrinterMappings(this.accountService.currentUser.id).subscribe(x => this.onUserPrintersLoadSuccesful(x), error => this.onDataLoadFailed(error));

        }

        this.alertService.showMessage('Defaults loaded!', '', MessageSeverity.info);

      },
        error => {
          this.alertService.stopLoadingMessage();
          this.alertService.showStickyMessage('Load Error', `Unable to retrieve user preferences from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
            MessageSeverity.error, error);
        });
  }


  //phoneNumberChanged() {
  //  if (this.userOTP.phoneNumber !== this.WhatsappNumber) {
  //    this.userOTP.optedIn = false;
  //  }
  //}

  generateOTP(): number {
    const min = 100000;
    const max = 999999;
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  //sendWappOTPMessage() {

  //  if (this.userOTP) {
  //    if (this.WhatsappNumber != this.userOTP.phoneNumber) {
  //      this.alertService.startLoadingMessage('', 'Sending Opt In');
  //      this.sysproService.addWappOptInDetails(this.userOTP).subscribe(x => this.onOPTInAdded(x), error => this.onOptinLoadFailed(error));
  //    }
  //  }
  //}


  //onOPTInAdded(x: OptIns): void {
  //  //Opt In Message sent
  //  this.alertService.stopLoadingMessage();
  //  this.setAsDefaultHelper();
  //  this.alertService.showMessage('Message Sent', 'Whatsapp Sent, reply to the message from your phone.', MessageSeverity.success);
  //}

  //onOptinLoadFailed(error: any): void {

  //  //Opt In Message sent
  //  this.alertService.stopLoadingMessage();
  //  this.setAsDefaultHelper();
  //  this.alertService.showMessage('Message Failed', 'Unable to send.', MessageSeverity.success);
  //}

  //onUserWappLoadSuccesful(x: any) {
   
  //  if (x) {
  //    this.userOTP = x;
  //    this.WhatsappNumber = this.userOTP.phoneNumber;
  //  }
  //}

    onUserLoadSuccesful(x: User): void {
      var userDetails = x;

      console.log(userDetails);

      //Check if user has whatsapp number
      this.userOTP = new OptIns();
      this.userOTP.channel = "whatsapp";
      this.userOTP.nextGenUser = this.accountService.currentUser.userName;
      this.userOTP.phoneNumber = this.accountService.currentUser.phoneNumber;
      this.userOTP.optedIn = false;
      this.userOTP.otp = this.generateOTP().toString();
      this.userOTP.profileName = this.accountService.currentUser.userName;
      this.WhatsappNumber = this.accountService.currentUser.phoneNumber;


      //Get the corresponsing record if any and update the userOTP
      // this.sysproService.GetMetaUserDetails(this.userOTP.nextGenUser).subscribe(x => this.onUserWappLoadSuccesful(x), error => this.onOptinLoadFailed(error));

      //Check for wapp settings
      this.sysproService.GetUserTel(this.accountService.currentUser.userName).subscribe(x => this.onTelReceived(x), error => this.onDataLoadFailed(error));
  }

  onTelReceived(x: string): void {
    console.log(x);
    this.WhatsappNumber = x;
    this.userOTP.optedIn = true;
    this.userOTP.phoneNumber = this.WhatsappNumber;

  }

    setAsDefault() {
        this.alertService.showDialog('Are you sure you want to set the current configuration as your new defaults?', DialogType.confirm,
            () => this.setAsDefaultHelper(),
            () => this.alertService.showMessage('Operation Cancelled!', '', MessageSeverity.default));
    }

    setAsDefaultHelper() {
      this.alertService.startLoadingMessage('', 'Saving new defaults');

      if (this.WhatsappNumber && this.WhatsappNumber.length > 5) {
        this.configurations.wappEnabled = true;
        this.userOTP.phoneNumber = this.WhatsappNumber;
        this.sysproService.addWappOptInDetails(this.userOTP).subscribe(x => { }, error => this.onDataLoadFailed(error));
      }

      console.log("configurations setAsDefaultHelper", this.configurations);
      this.configurations.themeId = (this.configurations.applicationName == "MaxArcusOnline" ? 17 : 15);
      this.configurations.branchCode = this.selectedBranchCode;
      this.configurations.defaultPrinter = this.selectedPrinter;
        this.accountService.updateUserPreferences(this.configurations.export())
            .subscribe(response => {
                this.alertService.stopLoadingMessage();
                this.alertService.showMessage('New Defaults', 'Account defaults updated successfully', MessageSeverity.success);

            },
            error => {
                this.alertService.stopLoadingMessage();
                this.alertService.showStickyMessage('Save Error', `An error occured whilst saving configuration defaults.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
                    MessageSeverity.error, error);
            });
    }



    resetDefault() {
        this.alertService.showDialog('Are you sure you want to reset your defaults?', DialogType.confirm,
            () => this.resetDefaultHelper(),
            () => this.alertService.showMessage('Operation Cancelled!', '', MessageSeverity.default));
    }

    resetDefaultHelper() {
        this.alertService.startLoadingMessage('', 'Resetting defaults');

      console.log("configurations Resetting defaults", this.configurations);

        this.accountService.updateUserPreferences(null)
            .subscribe(response => {
                this.alertService.stopLoadingMessage();
                this.configurations.import(null);
                this.alertService.showMessage('Defaults Reset', 'Account defaults reset completed successfully', MessageSeverity.success);

            },
            error => {
                this.alertService.stopLoadingMessage();
                this.alertService.showStickyMessage('Save Error', `An error occured whilst resetting configuration defaults.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
                    MessageSeverity.error, error);
            });
    }


    get canViewCustomers() {
        return this.accountService.userHasPermission(Permission.viewCustomersPermission); // eg. viewCustomersPermission
    }

    get canViewProducts() {
        return this.accountService.userHasPermission(Permission.viewInventoryPermission); // eg. viewProductsPermission
    }

  get canViewOrders() {
    return this.accountService.userHasPermission(Permission.viewQuotesPermission); // eg. viewProductsPermission
  }

  get canViewSalesOrders() {
    return this.accountService.userHasPermission(Permission.viewSalesOrdersPermission); // eg. viewProductsPermission
  }

  get canViewReports() {
    return this.accountService.userHasPermission(Permission.viewReportsPermission); // eg. viewProductsPermission
  }

  get canViewPurchaseOrders() {
    return this.accountService.userHasPermission(Permission.viewPurchaseOrdersPermission); // eg. viewProductsPermission
  }

  get canViewPODs() {
    return this.accountService.userHasPermission(Permission.viewPODsPermission); // eg. viewProductsPermission
  }

}
