import { Injectable } from '@angular/core';
import { Observable, Subject, forkJoin } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';

import { AccountEndpoint } from './account-endpoint.service';
import { FowkesOnlineEndpoint } from './fowkes-online-endpoint.service';
import { AuthService } from './auth.service';
import { Branch } from '../models/branch.model';
import { AspnetApplications } from '../models/applications.model';
import { CartHeader } from '../models/cart-header.model';
import { CartDetail } from '../models/cart-detail.model';
import { Establishment } from '../models/company-establishment.model';
import { CustomerOTPReference } from '../models/customerOTPReference.model';
import { CustomerPayments } from '../models/customer-payments.model';
import { ArCustomer } from '../models/arcustomer.model';
import { CustomerOTPHeader } from '../models/customerOTPHeader.model';
import { ITSDriver } from '../models/invoice-tracking-driver.model';
import { ITSVehicles } from '../models/invoice-tracking-vehicles.model';
import { ITSDriverCages, ITSDriverCagesDetail } from '../models/invoice-tracking-driver-cages.model';
import { TrackingAreas } from '../models/invoice-tracking-areas.model';
import { ITSLabelPrinting } from '../models/invoice-tracking-label-printing.model';
import { ITSParcelTracking, ITSParcelTrackingDetail } from '../models/invoice-tracking-parcels.model';
import { ITSDriversLog, ITSParcelTrackingDetailLog } from '../models/invoice-tracking-drivers-log.model';
import { ITSDriversReport } from '../models/invoice-tracking-driver-report.model';
import { WarehouseApplicationDefault } from '../models/warehouse-application-settings.model';
import { WarehouseUserDetails } from '../models/warehouse-user.model';

@Injectable()
export class FowkesOnlineService {
  
  constructor(
    private authService: AuthService,
    private fowkesOnlineEndpoint: FowkesOnlineEndpoint) {

  }

  get currentUser() {
    return this.authService.currentUser;
  }

  getBranches() {

    return this.fowkesOnlineEndpoint.getBranchesEndpoint<Branch[]>();
  }


  VerifyPassword(branch: string, type: string, stringToCompare: string ) {

    return this.fowkesOnlineEndpoint.verifyPasswordEndpoint(branch, type, stringToCompare);
  }


  getApplications() {

    return this.fowkesOnlineEndpoint.getApplicationsEndpoint<AspnetApplications[]>();
  }


  newCart(cartHeader: CartHeader) {
    return this.fowkesOnlineEndpoint.getNewCartEndpoint<CartHeader>(cartHeader);
  }

  newOTPDetail(otpReferenceDetails: CustomerOTPReference) {
    return this.fowkesOnlineEndpoint.newOTPDetailEndpoint<CustomerOTPReference>(otpReferenceDetails);
  }


  newOTPHeader(otpHeaderDetails: CustomerOTPHeader) {
    return this.fowkesOnlineEndpoint.newOTPHeaderEndpoint<CustomerOTPHeader>(otpHeaderDetails);
  }


  RemoveOTPCustomer(otpHeaderDetails: CustomerOTPHeader) {
    return this.fowkesOnlineEndpoint.RemoveOTPCustomerEndpoint<any>(otpHeaderDetails);
  }


  UpdateOTPCustomer(otpHeaderDetails: CustomerOTPHeader) {
    return this.fowkesOnlineEndpoint.UpdateOTPCustomerEndpoint<any>(otpHeaderDetails);
  }

  getOTPDetails(otp: string) {
    return this.fowkesOnlineEndpoint.getOTPDetailsEndpoint<CustomerOTPReference[]>(otp);
  }

  getCustomerPayments(startDate?: string, endDate?: string, customer?: string) {
    return this.fowkesOnlineEndpoint.getCustomerPaymentsEndpoint<CustomerPayments>(startDate, endDate, customer);
  }

  getCustomerDetail(customerId?: string) {
    return this.fowkesOnlineEndpoint.getCustomerDetailEndpoint<ArCustomer>(customerId);
  }

  getCartHeader(cartId: string) {
    return this.fowkesOnlineEndpoint.getCartHeaderEndpoint<CartHeader>(cartId);
  }

  cartDetail(cartDetail: CartDetail, cartId?: string) {
    return this.fowkesOnlineEndpoint.getCartDetailEndpoint<CartDetail>(cartDetail, cartId);
  }

  cartDetailDelete(cartId?: string, cartLineNumber?: number) {
    return this.fowkesOnlineEndpoint.getCartDetailDeleteEndpoint(cartId, cartLineNumber);
  }


  getAllEstablishmentDetails() {
    return this.fowkesOnlineEndpoint.getAllEstablishmentDetailsEndpoint<Establishment[]>();
  }

  editEstablishment(establishment: Establishment, id: number) {
    return this.fowkesOnlineEndpoint.editEstablishmentEndpoint<Establishment>(establishment, id);
  }

  newEstablishment(establishment: Establishment) {
    return this.fowkesOnlineEndpoint.newEstablishmentEndpoint<Establishment>(establishment);
  }

  deleteEstablishment(id: number) {
    return this.fowkesOnlineEndpoint.deleteEstablishmentEndpoint<Establishment>(id);
  }

  getAllDrivers() {
    return this.fowkesOnlineEndpoint.getAllDriversEndpoint<ITSDriver[]>();
  }

  addNewDriver(driver: ITSDriver) {
    return this.fowkesOnlineEndpoint.addNewDriverEndpoint<ITSDriver>(driver);
  }

  updateDriver(id: number, driver: ITSDriver) {
    return this.fowkesOnlineEndpoint.updateDriverEndpoint<ITSDriver>(id, driver);
  }

  deleteDriver(id: number) {
    return this.fowkesOnlineEndpoint.deleteDriverEndpoint<ITSDriver>(id);
  }

  getAllVehicles() {
    return this.fowkesOnlineEndpoint.getAllVehiclesEndpoint<ITSVehicles[]>();
  }

  addNewVehicle(vehicle: ITSVehicles) {
    return this.fowkesOnlineEndpoint.addNewVehicleEndpoint<ITSVehicles>(vehicle);
  }

  updateVehicle(id: number, vehicle: ITSVehicles) {
    return this.fowkesOnlineEndpoint.updateVehicleEndpoint<ITSVehicles>(id, vehicle);
  }

  deleteVehicle(id: number) {
    return this.fowkesOnlineEndpoint.deleteVehicleEndpoint<ITSVehicles>(id);
  }

  getAllDriverCages() {
    return this.fowkesOnlineEndpoint.getAllDriverCagesEndpoint<ITSDriverCages[]>();
  }

  addNewDriverCage(driverCageDto: any) {
    return this.fowkesOnlineEndpoint.addNewDriverCageEndpoint<ITSDriverCages>(driverCageDto);
  }

  updateDriverCage(id: number, driverCage: ITSDriverCages) {
    return this.fowkesOnlineEndpoint.updateDriverCageEndpoint<ITSDriverCages>(id, driverCage);
  }

  deleteDriverCage(id: number) {
    return this.fowkesOnlineEndpoint.deleteDriverCageEndpoint<ITSDriverCages>(id);
  }

  getAllTrackingAreas() {
    return this.fowkesOnlineEndpoint.getAllTrackingAreasEndpoint<TrackingAreas[]>();
  }

  addNewTrackingArea(area: TrackingAreas) {
    return this.fowkesOnlineEndpoint.addNewTrackingAreaEndpoint<TrackingAreas>(area);
  }

  updateTrackingArea(id: number, area: TrackingAreas) {
    return this.fowkesOnlineEndpoint.updateTrackingAreaEndpoint<TrackingAreas>(id, area);
  }

  deleteTrackingArea(id: number) {
    return this.fowkesOnlineEndpoint.deleteTrackingAreaEndpoint<TrackingAreas>(id);
  }

  //getAllPrinting() {
  //  return this.fowkesOnlineEndpoint.getAllPrintingEndpoint<ITSLabelPrinting[]>();
  //}


  getPrintingByDate(startDate?: string, endDate?: string) {
    return this.fowkesOnlineEndpoint.getPrintingByDateEndpoint<ITSLabelPrinting[]>(startDate, endDate);
  }

  initiatelabelprinting(id: number, numberOfLabels: number) {
    return this.fowkesOnlineEndpoint.initiatelabelprintingEndpoint<ITSLabelPrinting>(id, numberOfLabels);
  }


  updatePrinting(id: number, printing: ITSLabelPrinting) {
    return this.fowkesOnlineEndpoint.updatePrintingEndpoint<ITSLabelPrinting>(id, printing);
  }


  //Print directly to Zebra - Added to ITS COntroller
  printBarcodeLabelDirect(InvoiceNumber: string, Operator: string, SalesOrder: string, NoOfLabels: number, selectedPrinter: string) {
    return this.fowkesOnlineEndpoint.printBarcodeLabelDirectEndpoint<ITSLabelPrinting>(InvoiceNumber, Operator, SalesOrder, NoOfLabels, selectedPrinter);
  }

  //deletePrinting(id: number) {
  //  return this.fowkesOnlineEndpoint.deletePrintingEndpoint<ITSLabelPrinting>(id);
  //}


  //getITSLabelPDF(salesOrder?: string, invoice?: string, application?: string) {
  //  return this.fowkesOnlineEndpoint.getITSLabelPDFEndpoint<Blob>(salesOrder, invoice, application);
  //}

  getPrintCardPDF(cardType?: string, id?: string, application?: string) {
    return this.fowkesOnlineEndpoint.getPrintCardPDFEndpoint<Blob>(cardType, id, application);
  }

  getPrintLogBookPDF(cardType?: string, id?: string, sortOrder?: string) {
    return this.fowkesOnlineEndpoint.getPrintLogBookPDFEndpoint<Blob>(cardType, id, sortOrder);
  }

  //getAllTrackingList() {
  //  return this.fowkesOnlineEndpoint.getAllTrackingListEndpoint<ITSParcelTracking[]>();
  //}

  //getAllTrackingDetailList() {
  //  return this.fowkesOnlineEndpoint.getAllTrackingDetailListEndpoint<ITSParcelTrackingDetail[]>();
  //}
  getITSDriversReport(startDate?: string, endDate?: string) {
    return this.fowkesOnlineEndpoint.getITSDriversReportEndpoint<ITSDriversReport[]>(startDate, endDate);
  }

  getITSDriversLog(startDate?: string, endDate?: string) {
    return this.fowkesOnlineEndpoint.getITSDriversLogEndpoint<ITSDriversLog[]>(startDate, endDate);
  }
  getITSParcelTrackingDetail(startDate?: string, endDate?: string) {
    return this.fowkesOnlineEndpoint.getITSParcelTrackingDetailEndpoint<ITSParcelTrackingDetailLog[]>(startDate, endDate);
  }


  getITSDeliveryDetails(invoiceNumber?: string) {
    return this.fowkesOnlineEndpoint.getITSDeliveryDetailsEndpoint<ITSParcelTrackingDetailLog[]>(invoiceNumber);
  }


  getITSDeliveryDetailsAdditional(id?: number) {
    return this.fowkesOnlineEndpoint.getITSDeliveryDetailsAdditionalEndpoint<ITSDriversLog>(id);
  }



  getITSParcelTrackingDetailListForHeaderId(id?: number) {
    return this.fowkesOnlineEndpoint.getITSParcelTrackingDetailListForHeaderIdEndpoint<ITSParcelTrackingDetailLog[]>(id);
  }

  getAllDriverCagesDetail(): Observable<ITSDriverCagesDetail[]> {
    return this.fowkesOnlineEndpoint.getAllDriverCagesDetailEndpoint<ITSDriverCagesDetail[]>();
  }

  addNewDriverCageDetail(driverCageDetail: ITSDriverCagesDetail): Observable<ITSDriverCagesDetail> {
    return this.fowkesOnlineEndpoint.addNewDriverCageDetailEndpoint<ITSDriverCagesDetail>(driverCageDetail);
  }
  //updateDriverCageDetail(id: number, driverCageDetail: ITSDriverCagesDetail): Observable<ITSDriverCagesDetail> {
  //  return this.fowkesOnlineEndpoint.updateDriverCageDetailEndpoint<ITSDriverCagesDetail>(id, driverCageDetail);
  //}
  deleteDriverCageDetail(id: number): Observable<any> {
    return this.fowkesOnlineEndpoint.deleteDriverCageDetailEndpoint<any>(id);
  }

  updateparcels(id: number, numberOfLabels: number) {
    return this.fowkesOnlineEndpoint.updateparcelsEndpoint<ITSLabelPrinting>(id, numberOfLabels);
  }

  updateparcelsUndelivered(id: number, numberOfLabels: number, reason: string, user: string) {
    return this.fowkesOnlineEndpoint.updateparcelsUndeliveredEndpoint<ITSLabelPrinting>(id, numberOfLabels, reason, user);
  }


  updateDespatchParcels(id: number, details: ITSParcelTrackingDetailLog) {
    return this.fowkesOnlineEndpoint.updateDespatchParcelsEndpoint<ITSParcelTrackingDetailLog[]>(id, details);
  }


  printAdditionalLabels(invoiceNumber: string, Operator: string, SalesOrder: string, numberOfLabels: number) {
    return this.fowkesOnlineEndpoint.printAdditionalLabelsEndpoint<ITSLabelPrinting>(invoiceNumber, Operator, SalesOrder, numberOfLabels);
  }


  RefireLabelPrintingQueue() {
    return this.fowkesOnlineEndpoint.RefireLabelPrintingQueueEndpoint<any>();
  }


  RefireLogPrintingQueue() {
    return this.fowkesOnlineEndpoint.RefireLogPrintingQueueEndpoint<any>();
  }


  scanFingerprint() {
    return this.fowkesOnlineEndpoint.scanFingerprintEndpoint<any>();
  }


  enroll(fingerprintData: any) {
    return this.fowkesOnlineEndpoint.enrollEndpoint<any>(fingerprintData);
  }


  verify(fingerprintData: any) {
    return this.fowkesOnlineEndpoint.verifyEndpoint<any>(fingerprintData);
  }

  getITSWarehouseDefaults(): Observable<WarehouseApplicationDefault[]> {
    return this.fowkesOnlineEndpoint.getITSWarehouseDefaultsEndpoint<WarehouseApplicationDefault[]>();
  }

  updateITSWarehouseDefaults(defaultSetting: WarehouseApplicationDefault): Observable<any> {
    return this.fowkesOnlineEndpoint.updateITSWarehouseDefaultsEndpoint(defaultSetting);
  }

  getITSWarehouseUsers(): Observable<WarehouseUserDetails[]> {
    return this.fowkesOnlineEndpoint.getITSWarehouseUsersEndpoint<WarehouseUserDetails[]>();
  }

  //addITSWarehouseUser(user: WarehouseUser): Observable<any> {
  //  return this.fowkesOnlineEndpoint.addITSWarehouseUserEndpoint(user);
  //}

  updateITSWarehouseUser(user: WarehouseUserDetails): Observable<any> {
    return this.fowkesOnlineEndpoint.updateITSWarehouseUserEndpoint(user);
  }

  //deleteITSWarehouseUser(userId: number): Observable<any> {
  //  return this.fowkesOnlineEndpoint.deleteITSWarehouseUserEndpoint(userId);
  //}
}
