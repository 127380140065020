export class SysproUser {
  // Note: Using only optional constructor properties without backing store disables typescript's type checking for the type
  constructor() {
      }



  public sysproOperator: string;
  public sysproBuyer: string;
  public sysproRep: string;
  public fullName: string;
  public fowkesUser: string;
  public maxUser: string;
  public nextGenUser: string;
  public fowkesId: string;
  public maxId: string;
  public nextGenId: string;
  public defaultRole: string;
}
