export type PermissionNames =
    'View Users' | 'Manage Users' |
    'View Roles' | 'Manage Roles' | 'Assign Roles' |
  'View Customers' | 'Manage Customers' | 'Generate Customer Statements' | 'Maintain Customer Pricing' | 'Pricing Admin' | 'Bank Import' |
  'Place Order' | 'Disable Customer Pricing' |
  'View Inventory' | 'Manage Inventory' | 'Catalogue View' |
  'View Quotes' | 'Manage Quotes' |
  'View Sales Orders' | 'Manage Sales Orders' |
  'View Invoices' | 'Manage Invoices' | 'Reprint Invoices' |
  'View Purchase Orders' | 'Manage Purchase Orders' |
  'View Documents' | 'Manage Documents' | 'Webmail' |
  'View Reports' | 'Manage Reports' | 'View Invoice Reprint Report' | 'View ITS Productivity' | 'View ITS Control Report' | 'View Sales Reports' | 'View Debtors Release Report' | 'Export EFT Payments' |
  'View PODs' | 'Manage PODs' | 'Search PODs' | 'Missing PODs' | 'Equation PODs' | 'Batch Sync PODs' | 'Internal User' | 'External User' | 'View Web Quotes' | 'View ITS Config' | 'Manage ITS Config';

export type PermissionValues =
    'users.view' | 'users.manage' |
    'roles.view' | 'roles.manage' | 'roles.assign'|
  'customers.view' | 'customers.manage' | 'customers.statements' | 'customers.maintainpricing' | 'customers.pricingadmin'  | 'customers.bankimport' |
  'cart.create' |  'cart.maintainpricing' |
  'inventory.view' | 'inventory.manage' | 'catalogue.view' |
  'quotes.view' | 'quotes.manage' |
  'salesorders.view' | 'salesorders.manage' | 'salesorders.release' |
  'invoices.view' | 'invoices.manage' | 'invoices.reprint' |
  'purchaseorders.view' | 'purchaseorders.manage' |
  'documents.view' | 'documents.manage' | 'documents.webmail' |
  'reports.view' | 'reports.manage' | 'reportsreprint.manage' | 'reportsproductivity.manage' | 'itscontrolreports.manage' | 'reportssales.manage' | 'reportdebtorsrelease.manage' | 'bankeftexport.manage' |
  'pods.view' | 'pods.manage' | 'pods.search' | 'pods.missing' | 'pods.equation' | 'pods.batchsync' | 'nextgen.internal' | 'nextgen.external' | 'quotes.webquotes' | 'itsconfig.view' | 'itsconfig.manage';

export class Permission {

  public static readonly viewUsersPermission: PermissionValues = 'users.view';
  public static readonly manageUsersPermission: PermissionValues = 'users.manage';

  public static readonly viewRolesPermission: PermissionValues = 'roles.view';
  public static readonly manageRolesPermission: PermissionValues = 'roles.manage';
  public static readonly assignRolesPermission: PermissionValues = 'roles.assign';

  public static readonly viewCustomersPermission: PermissionValues = 'customers.view';
  public static readonly manageCustomersPermission: PermissionValues = 'customers.manage';
  public static readonly customerStatementsPermission: PermissionValues = 'customers.statements';
  public static readonly maintainCustomerPricingPermission: PermissionValues = 'customers.maintainpricing';
  public static readonly customerPricingAdminPermission: PermissionValues = 'customers.pricingadmin';
  public static readonly bankImportPermission: PermissionValues = 'customers.bankimport';

  public static readonly placeOrderPermission: PermissionValues = 'cart.create';
  public static readonly cartPricingPermission: PermissionValues = 'cart.maintainpricing';

  public static readonly viewInventoryPermission: PermissionValues = 'inventory.view';
  public static readonly manageInventoryPermission: PermissionValues = 'inventory.manage';
  public static readonly viewCataloguePermission: PermissionValues = 'catalogue.view';
  
  public static readonly viewQuotesPermission: PermissionValues = 'quotes.view';
  public static readonly manageQuotesPermission: PermissionValues = 'quotes.manage';
  public static readonly webQuotesPermission: PermissionValues = 'quotes.webquotes';

  public static readonly viewSalesOrdersPermission: PermissionValues = 'salesorders.view';
  public static readonly manageSalesOrdersPermission: PermissionValues = 'salesorders.manage';
  public static readonly releaseSalesOrdersPermission: PermissionValues = 'salesorders.release';

  public static readonly viewInvoicesPermission: PermissionValues = 'invoices.view';
  public static readonly manageInvoicesPermission: PermissionValues = 'invoices.manage';
  public static readonly reprintInvoicesPermission: PermissionValues = 'invoices.reprint';

  public static readonly viewPurchaseOrdersPermission: PermissionValues = 'purchaseorders.view';
  public static readonly managePurchaseOrdersPermission: PermissionValues = 'purchaseorders.manage';

  public static readonly viewDocumentsPermission: PermissionValues = 'documents.view';
  public static readonly manageDocumentsPermission: PermissionValues = 'documents.manage';
  public static readonly webmailPermission: PermissionValues = 'documents.webmail';

  public static readonly viewReportsPermission: PermissionValues = 'reports.view';
  public static readonly manageReportsPermission: PermissionValues = 'reports.manage';
  public static readonly reprintReportsPermission: PermissionValues = 'reportsreprint.manage';
  public static readonly productivityReportsPermission: PermissionValues = 'reportsproductivity.manage';
  public static readonly itsControlReportsPermission: PermissionValues = 'itscontrolreports.manage';
  public static readonly bankEFTExportPermission: PermissionValues = 'bankeftexport.manage';
  public static readonly salesReportsPermission: PermissionValues = 'reportssales.manage';
  public static readonly debtorsReleaseReportsPermission: PermissionValues = 'reportdebtorsrelease.manage';

  public static readonly viewPODsPermission: PermissionValues = 'pods.view';
  public static readonly searchPODsPermission: PermissionValues = 'pods.search';
  public static readonly missingPODsPermission: PermissionValues = 'pods.missing';
  public static readonly managePODsPermission: PermissionValues = 'pods.manage';
  public static readonly batchSyncPODsPermission: PermissionValues = 'pods.batchsync';
  public static readonly eqationPODsPermission: PermissionValues = 'pods.equation';

  public static readonly viewITSConfigPermission: PermissionValues = 'itsconfig.view';
  public static readonly manageITSConfigPermission: PermissionValues = 'itsconfig.manage';

  public static readonly internalUserPermission: PermissionValues = 'nextgen.internal';
  public static readonly externalUserPermission: PermissionValues = 'nextgen.external';

  constructor(name?: PermissionNames, value?: PermissionValues, groupName?: string, description?: string) {
    this.name = name;
    this.value = value;
    this.groupName = groupName;
    this.description = description;
  }

  public name: PermissionNames;
  public value: PermissionValues;
  public groupName: string;
  public description: string;
}
